import React from 'react';
import { useQuery } from 'react-query';
import { homepage } from '../../services/user';
import { useEffect } from 'react';
import Loader from '../../components/loader/loader';
import { reactQueryOptions } from '../../utils/reactQueryOptions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import './home.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useToastContext } from '../../components/toast/toast';
import { routes } from '../../utils/vars';
import { setFilters } from '../../redux/features/defaultTableFilters';

export default function Home() {
    const {
        data: homepageData,
        isLoading,
        error,
    } = useQuery('homepage-data', homepage, reactQueryOptions);
    const theme = useSelector((state) => state.theme);
    let wrapperClasses = 'conditions-drop-wrapper';
    wrapperClasses += theme === 'dark' ? ' paper-dark-theme' : ' paper';
    const { showErrorToast } = useToastContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            showErrorToast(error.response?.data?.message);
        }
    }, [error]);

    if (isLoading) {
        return <Loader height={'80vh'} />;
    }

    const {
        user,
        completedCasesCount,
        awaitingCompletion,
        toBeMonitored,
        latestRuleChanges,
        latestActivities,
        awaitingCompletionPending,
    } = homepageData;

    const clickHandler = (type) => {
        if (type === 'assignee') {
            dispatch(
                setFilters({
                    key: 'monitoringQueue',
                    filter: {
                        column: 'assignee',
                        value: user.name && user.name.toLowerCase(),
                        operator: '=',
                    },
                })
            );
        }

        if (type === 'status') {
            dispatch(
                setFilters({
                    key: 'monitoringQueue',
                    filter: {
                        column: 'status',
                        value: 'pending',
                        operator: '=',
                    },
                })
            );
        }
        navigate(routes.MONITORING_QUEUE);
    };

    const toBeMonitoredItems = toBeMonitored.map((e, i) => {
        return (
            <li key={i}>
                {e.name} -
                <Link target="_blank" to={`flag-details/${e.id}`}>
                    Link
                </Link>
            </li>
        );
    });

    const latestChangesItems = latestRuleChanges.map((e, i) => {
        return (
            <li key={i}>
                {e.userName} {e.status} {e.ruleName} on{' '}
                {moment(e.date).format('M/D/YYYY, h:mm A')}
            </li>
        );
    });

    const latestActivitiesItems = latestActivities.map((e, i) => {
        return (
            <li key={i}>
                {e.userName} {e.status} {e.ruleName} on{' '}
                {moment(e.dateTime).format('M/D/YYYY, h:mm A')} {e.description}
            </li>
        );
    });

    return (
        <div className="homepage-wrapper">
            <h2 className="center">
                Hello, {user.name}! Welcome on{' '}
                {moment(homepageData?.currentDate).format('DD-MM-YYYY')} at{' '}
                {moment(homepageData?.currentDate).format('HH:mm')}
            </h2>
            <div className={`${wrapperClasses} center header-section`}>
                Good Job! In the last 7 days there are {completedCasesCount}{' '}
                completed cases
            </div>
            <div className="homepage-grid">
                <div className={`${wrapperClasses} section`}>
                    <h4 className="center">Up for today</h4>
                    <p>
                        There are{' '}
                        <span
                            className="link"
                            onClick={() => clickHandler('assignee')}
                        >
                            {awaitingCompletion?.length || 0}
                        </span>{' '}
                        number of cases, assigned to you and{' '}
                        <span
                            className="link"
                            onClick={() => clickHandler('status')}
                        >
                            {awaitingCompletionPending?.length || 0}
                        </span>{' '}
                        cases in status PENDING.
                    </p>
                </div>
                <div className={`${wrapperClasses} section`}>
                    <h4 className="center">Attention!</h4>
                    <p>
                        Please, pay special attention to the cases, which are
                        due to be monitored today.
                    </p>
                    <ol>{toBeMonitoredItems}</ol>
                </div>
                <div className={`${wrapperClasses} section`}>
                    <h4 className="center">Latest Rule Changes</h4>
                    <ol>{latestChangesItems}</ol>
                </div>
                <div className={`${wrapperClasses} section`}>
                    <h4 className="center">Latest Activity</h4>
                    <ol>{latestActivitiesItems}</ol>
                </div>
            </div>
        </div>
    );
}
