import moment from "moment";
import { useSelector } from "react-redux";
import { useCheckPermission } from "../../../hooks/useCheckPermission";
import {
  flagStatuses,
  languageLabels,
  resolutionStatuses,
  statusColorClasses,
} from "../../../utils/vars";

export default function Details({
  id,
  date,
  status,
  rule,
  tenant,
  author,
  resolutionStatus,
  assignee,
}) {
  const lg = useSelector((state) => state.language);
  let statusClassName =
    "padding-left status-label " + statusColorClasses[status];
  const resolutionTextPermission = useCheckPermission("RESOLUTION_TEXT");

  return (
    <div className="flag-details-section">
      <h4>Details</h4>
      <table className="flag-details-tab">
        <tbody>
          <tr>
            <td>Flagged date:</td>
            <td>{moment(date).format("M/D/YYYY, h:mm A")}</td>
          </tr>
          <tr>
            <td>ID:</td>
            <td>{id}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>
              <p className={statusClassName}>{flagStatuses[status]}</p>
            </td>
          </tr>
          {resolutionTextPermission && (
            <tr>
              <td>Resolution status:</td>
              <td>{resolutionStatuses[resolutionStatus]}</td>
            </tr>
          )}

          <tr>
            <td>{languageLabels.rule[lg]}:</td>
            <td>{rule}</td>
          </tr>
          <tr>
            <td>{languageLabels.tenant[lg]}:</td>
            <td>{tenant}</td>
          </tr>
          <tr>
            <td>{languageLabels.author[lg]}:</td>
            <td>{author}</td>
          </tr>
          <tr>
            <td>Assignee:</td>
            <td>{assignee}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
