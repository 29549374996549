import React, { useRef, useState } from 'react';
import { useCheckPermission } from '../../../hooks/useCheckPermission';
import CustomStore from 'devextreme/data/custom_store';
import axios from '../../../services/axios/config';
import Loader from '../../../components/loader/loader';
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow } from 'devextreme-react/data-grid';
import { dateFilterOperations, errorMsg, forMonitoringFilterSelectors, resolutionStatuses, routes } from '../../../utils/vars';
import ExportButtons from '../exportButtons/exportButtons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedTreeViewItem } from '../../../redux/features/treeViewChangeSelected';
import { setBackButtonRoute } from '../../../redux/features/flagDetailsBackButtonRoute';
import { getFilterParams } from '../../../utils/functions';

export default function ForMonitoring() {
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [hasFetchError, setHasFetchError] = useState(true);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');
    const filterParamsRef = useRef('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const customStore = new CustomStore({
        key: '',
        load(loadOptions) {
            const skip = loadOptions.skip;
            const take = loadOptions.take;
            const page = skip / take;
            const obj = getFilterParams(loadOptions.filter, forMonitoringFilterSelectors, 'object');
            filterParamsRef.current = JSON.stringify(obj);
            
            const body = Object.assign({
                type: 'FOR_MONITORING',
                forExport: false,
                reportExportType: 'PDF',
            }, obj);

            return axios
                .post(`report?page=${page}&size=${take}`, body)
                .then((res) => {
                    const data = res.data;
                    setHasFetchError(false);
                    return {
                        data: data.data,
                        totalCount: data.elements,
                    };
                })
                .catch((err) => {
                    console.error(err);
                    setHasFetchError(true);
                    throw errorMsg;
                });
        },
    });

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    const onRowClick = (flagId) => {
        dispatch(setSelectedTreeViewItem(3));
        navigate(`/flag-details/${flagId}`);
        dispatch(setBackButtonRoute(routes.CASES_FOR_MONITORING));
    };

    return (
        <div className="reports-wrapper for-monitoring-wrapper">
            <div className="flex-container download-btns">
                {exportPermission && (
                    <ExportButtons
                        hasFilters={true}
                        disabled={hasFetchError}
                        setLoading={setDownloadLoading}
                        reportType={'FOR_MONITORING'}
                        filterParam={filterParamsRef.current}
                    />
                )}
                {/* <Button
                      text={languageLabels.generateReport[lg]}
                      type="default"
                  /> */}
            </div>
            <DataGrid
                allowColumnResizing={true}
                dataSource={customStore}
                remoteOperations={{ paging: true, filtering: true }}
                hoverStateEnabled={true}
                onRowClick={(ev) => onRowClick(ev.data.flagId)}
            >
                <FilterRow visible />
                <Column
                    allowSorting={false}
                    caption="Flag ID"
                    dataField={'flagId'}
                    alignment={'left'}
                    filterOperations={['=']}
                />

                <Column
                    allowFiltering={true}
                    allowSorting={false}
                    caption="Resolution"
                    dataField={'resolution'}
                    customizeText={(cellInfo) =>
                        resolutionStatuses[cellInfo.value]
                    }
                    // dataType={''}
                    filterOperations={['=']}
                />
                <Column
                    allowSorting={false}
                    caption="Resolution date"
                    dataField={'resolutionDate'}
                    dataType={'date'}
                    format={'yyyy-MM-dd HH:mm'}
                    editorOptions={{
                        type: 'date',
                        displayFormat: 'yyyy-MM-dd',
                    }}
                    filterOperations={dateFilterOperations}
                />
                <Column
                    allowSorting={false}
                    caption="Due date"
                    dataField={'dueDate'}
                    dataType={'date'}
                    editorOptions={{
                        type: 'date',
                        displayFormat: 'yyyy-MM-dd',
                    }}
                    filterOperations={dateFilterOperations}

                />
                <Column
                    allowSorting={false}
                    caption="Execution check"
                    dataField={'executionCheck'}
                    filterOperations={['=']}
                />
                <Column
                    allowSorting={false}
                    caption="Tenant"
                    dataField={'tenant'}
                    filterOperations={['=']}
                />
                <Column
                    allowFiltering={true}
                    allowSorting={false}
                    caption="Assignee"
                    dataField={'assignee'}
                    alignment={'left'}
                    dataType={''}
                    filterOperations={['=']}
                />
            </DataGrid>
        </div>
    );
}
