import { apiBaseUrl, clientBaseUrl } from '../urls';
import axios from 'axios';
import { useKeycloak } from '../../App';

const instance = axios.create({
    baseURL: apiBaseUrl,
});

export default instance;

export const useAxiosConfig = () => {
    const { keycloak } = useKeycloak();
    const updateToken = (successCallback) =>
        keycloak
            .updateToken(5)
            .then(successCallback)
            .catch(() => {
                localStorage.removeItem('tenantSettings');
                window.location = clientBaseUrl;
            });
    const requestInterceptor = () => {
        const tenant = JSON.parse(localStorage.getItem('tenantSettings') || {});

        instance.interceptors.request.use((config) => {
            if (!!tenant) {
                config.headers.tenant = tenant.name;
            }

            if (!!keycloak.token) {
                const cb = () => {
                    config.headers.Authorization = `Bearer ${keycloak.token}`;
                    return Promise.resolve(config);
                };
                return updateToken(cb);
            }
        });
    };

    // const responseInterceptor = () => {
    //     instance.interceptors.response.use(
    //         (response) => {
    //             return response;
    //         },
    //         (error) => {
    //             console.log(error);
    //             if (error.response?.status === 401) {
    //                 localStorage.removeItem('tenantSettings');
    //                 window.location = clientBaseUrl;
    //             }
    //             return Promise.reject(error);
    //         }
    //     );
    // };

    requestInterceptor();
    // responseInterceptor();
};
