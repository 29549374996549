import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useActionsRoleContext } from "../../contexts/actionsRoleProvider";
import { useToastContext } from "../toast/toast";
import CustomStore from "devextreme/data/custom_store";
import { getDirection, getFilterParams } from "../../utils/functions";
import {
  dateFilterOperations,
  errorMsg,
  flagStatuses,
  ruleFilterSelectors,
  rulesOrderSelectors,
  rulesTableTypes,
  useGetLocalizedLabel,
} from "../../utils/vars";
import { setSearchParams } from "../../redux/features/rulesSearchParams";
import { deleteRule, getAllPaginated } from "../../services/rules";
import { Button, DataGrid, Popup, Tooltip } from "devextreme-react";
import { scroll } from "../../redux/features/rulesScroll";
import { Column, FilterRow } from "devextreme-react/data-grid";
import ActivateDeactivate from "./ActivateDeactivate";
import { unselect } from "../../redux/features/unselectTreeView";

const InfoBtn = ({ data }) => {
  const divRef = useRef();

  return (
    <div id={`id-${data.data.id}`} ref={divRef}>
      <Button type="default" stylingMode="text" icon="info" />
      <Tooltip
        className="rule-tooltip"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        target={`#id-${data.data.id}`}
      >
        <div className="tooltip-rule-description">
          {data.data.description || "No description"}
        </div>
      </Tooltip>
    </div>
  );
};

export default function Table({ searchValue = "", rulesType }) {
  const navigate = useNavigate();
  const [popupConfig, setPopupConfig] = useState({ open: false });
  const [dataSource, setDataSource] = useState(null);
  const dispatch = useDispatch();
  const { role } = useActionsRoleContext();
  const deleteRulePermission = role === "GRANT_ADMIN" || role === "ADMIN";
  const { showSuccessToast, showErrorToast } = useToastContext();
  const [refetchTable, setRefetchTable] = useState(0);
  const getLocalized = useGetLocalizedLabel();

  useEffect(() => {
    setDataSource(
      new CustomStore({
        key: "id",
        load: async ({ skip, take, sort, filter, ...rest }) => {
          const page = skip / take;
          const direction = getDirection(sort);
          const type = sort ? rulesOrderSelectors[sort[0].selector] : "DATE";
          const filterParams = filter
            ? "&" + getFilterParams(filter, ruleFilterSelectors)
            : "";
          dispatch(
            setSearchParams(
              `?direction=${direction}&type=${type}&${filterParams}`
            )
          );

          // console.log('rulesType', rulesType);
          try {
            return await getAllPaginated(
              page,
              take,
              searchValue,
              direction,
              type,
              filterParams,
              rulesType === rulesTableTypes.BASIC ? "BASIC" : "ADVANCED_SEARCH"
            );
          } catch (err) {
            console.error(err);
            throw errorMsg;
          }
        },
      })
    );
  }, [searchValue, refetchTable]);

  async function deleteHandler() {
    try {
      await deleteRule(popupConfig.id);
      showSuccessToast("Successfully deleted rule");
    } catch (err) {
      console.error(err);
      showErrorToast(err.response?.data?.message || errorMsg);
    }

    setPopupConfig({ visible: false });
  }

  const rowClickHandler = (r) => {
    if (rulesType === rulesTableTypes.CUSTOM) {
      return;
    }
    dispatch(unselect());
    navigate(`/rule-dashboard/${r.data?.id}`);
  };

  return (
    <>
      <DataGrid
        remoteOperations={{
          paging: true,
          sorting: true,
          filtering: true,
        }}
        hoverStateEnabled={true}
        dataSource={dataSource}
        onRowClick={rowClickHandler}
        onContentReady={() => {
          const pageBtns = document.querySelector(".dx-pages");
          pageBtns?.addEventListener("click", () => {
            dispatch(scroll());
          });
        }}
      >
        <FilterRow visible={true} />

        {/* <RemoteOperations filtering paging sorting /> */}
        <Column
          dataField="dateCreated"
          caption={getLocalized("date")}
          dataType={"date"}
          // filterOperations={['=', 'between']}
          filterOperations={dateFilterOperations}
          editorOptions={{
            type: "date",
            displayFormat: "yyyy-MM-dd",
          }}
        />
        <Column
          dataField="author"
          caption={getLocalized("author")}
          cellRender={(data) => data.data?.author?.name}
          filterOperations={["="]}
        />
        <Column
          dataField="name"
          caption={getLocalized("ruleName")}
          filterOperations={["="]}
        />
        {rulesType === rulesTableTypes.BASIC && (
          <Column
            dataField="typeOfRule"
            caption={getLocalized("ruleType")}
            filterOperations={["="]}
          />
        )}
        <Column
          dataField="status"
          caption={getLocalized("status")}
          cellRender={(data) => flagStatuses[data.data.status]}
          filterOperations={["="]}
        />
        {rulesType === rulesTableTypes.CUSTOM && (
          <Column
            cellRender={(data) => {
              return (
                <ActivateDeactivate
                  {...data.data}
                  refetchRules={() => setRefetchTable(refetchTable + 1)}
                />
              );
            }}
          />
        )}
        {deleteRulePermission && (
          <Column
            cellRender={(data) => {
              // if (data.data.status === 'ACTIVATED') {
              //     return null;
              // }

              return (
                <Button
                  text={getLocalized("delete")}
                  type="default"
                  stylingMode="outlined"
                  onClick={(ev) => {
                    ev.event.stopPropagation();
                    setPopupConfig({
                      open: true,
                      id: data.data.id,
                    });
                  }}
                />
              );
            }}
          />
        )}
        {rulesType === rulesTableTypes.BASIC && (
          <Column cellComponent={InfoBtn} filterOperations={[]} />
        )}
      </DataGrid>
      <Popup
        visible={popupConfig?.open}
        showTitle={false}
        width={280}
        height={160}
      >
        <p style={{ fontSize: "20px", textAlign: "center" }}>
          Are you sure that you want to delete this
          {rulesType === rulesTableTypes.CUSTOM ? " report?" : " rule?"}
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            text="Cancel"
            stylingMode="outlined"
            type="success"
            onClick={() => setPopupConfig({ open: false })}
            width={100}
          />
          <Button
            text="Confirm"
            stylingMode="outlined"
            type="danger"
            onClick={deleteHandler}
            width={100}
          />
        </div>
      </Popup>
    </>
  );
}
