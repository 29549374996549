import { useSelector } from "react-redux";

export const flagStatuses = {
  IN_PROGRESS: "In progress",
  COMPLETED: "Completed",
  ESCALATED: "Escalated",
  PENDING: "Pending",
  CREATED: "Created",
  ACTIVATED: "Activated",
  NOT_ACTIVATED: "Not activated",
  DEACTIVATED: "Deactivated",
};

export const reportsTypes = {
  SINGLE: "Single",
  MULTI: "Multy",
  DAILY: "Daily",
};

export const resolutionStatuses = {
  DOCUMENT_ENQUIRY: "Document Enquiry",
  FOR_INVESTIGATION: "For Investigation",
  FALSE_POSITIVE: "False Positive",
  TERMINATION_OF_CONTRACT: "Termination of the Contract",
  STOP_OPERATION: "Stop the Operation",
  FOR_REPORTING: "For Reporting",
  FOR_MONITORING: "For Monitoring",
  INCREASEDRISK: "Increased Risk",
  DECREASEDRISK: "Decreased Risk",
};

export const useGetLocalizedLabel = () => {
  const lg = useSelector((state) => state.language);

  //TODO finish refactoring and use this everywhere for localization
  return (label) => {
    const match = languageLabels[label] && languageLabels[label][lg];

    if (match) {
      return match;
    }

    return languageLabels[label] && languageLabels[label]["en-US"];
  };
};

export const languageLabels = {
  fromDate: {
    "en-US": "From date",
    "bg-BG": "От дата",
  },
  logout: {
    "en-US": "Logout",
    "bg-BG": "Logout",
  },
  create: {
    "en-US": "Create",
    "bg-BG": "Създай",
  },
  createRule: {
    "en-US": "Create rule",
    "bg-BG": "Създай правило",
  },
  rules: {
    "en-US": "Rules",
    "bg-BG": "Правила",
  },
  reports: {
    "en-US": "Reports",
    "bg-BG": "Репорти",
  },
  singleReports: {
    "en-US": " - Single Client Report",
    "bg-BG": "Доклад за един клиент",
  },
  multipleReports: {
    "en-US": " - Multiple Client Report",
    "bg-BG": "Доклад за множество клиенти",
  },
  dailyOverlimitReports: {
    "en-US": " - Daily Report for Overlimit",
    "bg-BG": "Дневен доклад за надхвърлен лимит",
  },
  forMonitoringReports: {
    "en-US": " - Report for Cases for Monitoring",
    "bg-BG": "Доклад за случаите за мониторинг",
  },
  customReports: {
    "en-US": " - Custom Reports",
    "bg-BG": "Персонализирани доклади",
  },
  auditTrail: {
    "en-US": "Audit Trial",
    "bg-BG": "Одит",
  },
  details: {
    "en-US": "Details",
    "bg-BG": "Details",
  },
  back: {
    "en-US": "Back",
    "bg-BG": "Back",
  },
  showGroups: {
    "en-US": "Show groups",
    "bg-BG": "Покажи групи",
  },
  hideGroups: {
    "en-US": "Hide groups",
    "bg-BG": "Скрий групи",
  },
  entities: {
    "en-US": "Entities",
    "bg-BG": "Ентитита",
  },
  test: {
    "en-US": "Test",
    "bg-BG": "Test",
  },
  conditions: {
    "en-US": "Conditions",
    "bg-BG": "Условия",
  },
  date: {
    "en-US": "Date",
    "bg-BG": "Дата",
  },
  ruleName: {
    "en-US": "Rule name",
    "bg-BG": "Име на правилото",
  },
  ruleData: {
    "en-US": "Rule data",
    "bg-BG": "Данни за правилото",
  },
  tenant: {
    "en-US": "Tenant",
    "bg-BG": "Тенант",
  },
  assignee: {
    "en-US": "Assignee",
    "bg-BG": "Правоприменик",
  },
  status: {
    "en-US": "Status",
    "bg-BG": "Статус",
  },
  resolution: {
    "en-US": "Resolution",
    "bg-BG": "Резолюция",
  },
  employee: {
    "en-US": "Employee",
    "bg-BG": "Служител",
  },
  changeStatus: {
    "en-US": "Change Status",
    "bg-BG": "Смени статус",
  },
  changeRisk: {
    "en-US": "Change risk in AML",
    "bg-BG": "Change risk in AML",
  },
  history: {
    "en-US": "History",
    "bg-BG": "History",
  },
  monitoringQueue: {
    "en-US": "Monitoring queue",
    "bg-BG": "Мониторинг",
  },
  pin: {
    "en-US": "PIN",
    "bg-BG": "ПИН",
  },
  settings: {
    "en-US": "Settings",
    "bg-BG": "Настройки",
  },
  language: {
    "en-US": "Language",
    "bg-BG": "Език",
  },
  save: {
    "en-US": "Save",
    "bg-BG": "Запази",
  },
  name: {
    "en-US": "First name",
    "bg-BG": "Име",
  },
  author: {
    "en-US": "Author",
    "bg-BG": "Служител",
  },
  groupBy: {
    "en-US": "Group by",
    "bg-BG": "Групирай по",
  },
  selectedEntities: {
    "en-US": "Selected entities",
    "bg-BG": "Избрани ентитита",
  },
  dataFields: {
    "en-US": "Data fields",
    "bg-BG": "Полета",
  },
  addGroup: {
    "en-US": "Add group",
    "bg-BG": "Добави група",
  },
  confirm: {
    "en-US": "Confirm",
    "bg-BG": "Потвърди",
  },
  deleteGroup: {
    "en-US": "Delete group",
    "bg-BG": "Изтрий група",
  },
  addGroupToGroup: {
    "en-US": "Add group to group",
    "bg-BG": "Добави група към група",
  },
  addFieldToGroup: {
    "en-US": "Add field to group",
    "bg-BG": "Добави поле към група",
  },
  exactValue: {
    "en-US": "Exact value",
    "bg-BG": "Стойност",
  },
  entity: {
    "en-US": "Entity",
    "bg-BG": "Ентити",
  },
  group: {
    "en-US": "Group",
    "bg-BG": "Група",
  },
  field: {
    "en-US": "Field",
    "bg-BG": "Поле",
  },
  dropEntityHere: {
    "en-US": "Drop entity here",
    "bg-BG": "Пусни ентити тук",
  },
  enterValue: {
    "en-US": "Enter value",
    "bg-BG": "Въведи стойност",
  },
  missingData: {
    "en-US": "Missing data",
    "bg-BG": "Липсват данни",
  },
  function: {
    "en-US": "Function",
    "bg-BG": "Функция",
  },
  clear: {
    "en-US": "Clear",
    "bg-BG": "Clear",
  },
  flaggedDate: {
    "en-US": "Flagged date",
    "bg-BG": "Дата",
  },
  rule: {
    "en-US": "Rule",
    "bg-BG": "Правило",
  },
  data: {
    "en-US": "Data",
    "bg-BG": "Данни",
  },
  resolutionStatusHistory: {
    "en-US": "Resolution status history",
    "bg-BG": "История на резолюшън статуса",
  },
  reviewStatusHistory: {
    "en-US": "Review status history",
    "bg-BG": "История на преглед статуса",
  },
  flaggedData: {
    "en-US": "Flagged data",
    "bg-BG": "Данни за флага",
  },
  lastName: {
    "en-US": "Last name",
    "bg-BG": "Фамилия",
  },
  cancel: {
    "en-US": "Cancel",
    "bg-BG": "Откажи",
  },
  activate: {
    "en-US": "Activate",
    "bg-BG": "Activate",
  },
  changeHistory: {
    "en-US": "Change history",
    "bg-BG": "История на промените",
  },
  changeType: {
    "en-US": "Change type",
    "bg-BG": "Вид промяна",
  },
  sinceDate: {
    "en-US": "Since date",
    "bg-BG": "От дата",
  },
  deactivate: {
    "en-US": "Deactivate",
    "bg-BG": "Деактивирай",
  },
  clone: {
    "en-US": "Clone",
    "bg-BG": "Клонирай",
  },
  newRuleName: {
    "en-US": "New rule name",
    "bg-BG": "Име на новото правило",
  },
  generateReport: {
    "en-US": "Generate report",
    "bg-BG": "Generate report",
  },
  theme: {
    "en-US": "Theme",
    "bg-BG": "Тема",
  },
  deleteField: {
    "en-US": "Delete field",
    "bg-BG": "Изтрий поле",
  },
  generateChart: {
    "en-US": "Generate chart",
    "bg-BG": "Generate chart",
  },
  editName: {
    "en-US": "Edit name",
    "bg-BG": "Редактирай име",
  },
  exportExcel: {
    "en-US": "Export as excel",
    "bg-BG": "Експортирай като ексел",
  },
  exportPdf: {
    "en-US": "Export as pdf",
    "bg-BG": "Експортирай като pdf",
  },
  activityLog: {
    "en-US": "Activity log",
    "bg-BG": "Активност",
  },
  report: {
    "en-US": "Report",
    "bg-BG": "Доклад",
  },
  manualSearch: {
    "en-US": "Manual search",
    "bg-BG": "Ръчно търсене",
  },
  advancedSearch: {
    "en-US": "Advanced search",
    "bg-BG": "Разширено търсене",
  },
  ruleType: {
    "en-US": "Rule type",
    "bg-BG": "Тип правило",
  },
  delete: {
    "en-US": "Delete",
    "bg-BG": "Delete",
  },
};

export const reportsLabels = {
  SINGLE: "Single",
  MULTI: "Multi",
  DAILY: "Daily",
  FOR_MONITORING: "For monitoring",
};

export const conditionalIntervals = {
  DAY_BEFORE_NOW: "Days before now",
  MONTH_BEFORE_NOW: "Months before now",
  YEAR_BEFORE_NOW: "Years before now",
};

export const conditionalIntervalsPlural = {
  DAY_BEFORE_NOW: "Days before now",
  MONTH_BEFORE_NOW: "Months before now",
  YEAR_BEFORE_NOW: "Years before now",
};

export const rolesLabels = {
  GRANT_ADMIN: "Grant admin",
  ADMIN: "Admin",
  AML_CORDINATOR: "Backoffice specialist 1st level",
  AML_MANAGER: "Backoffice specialist 2nd level",
  AUDITOR: "Auditor",
};

export const toastDisplayTime = 1500;

export const statusColorClasses = {
  PENDING: "status-red",
  IN_PROGRESS: "status-orange",
  COMPLETED: "status-green",
  ESCALATED: "status-orange",
};

export const errorMsg = "Something happened. Try again later.";

export const rulesOrderSelectors = {
  dateCreated: "DATE",
  author: "AUTHOR",
  name: "RULE_NAME",
  status: "STATUS",
  typeOfRule: "RULE_TYPE",
};

export const ruleFilterSelectors = {
  name: "ruleName",
  dateCreated: "dateCreated",
  author: "author",
  status: "status",
  typeOfRule: "typeOfRule",
};

export const monitoringQueueOrderSelectors = {
  date: "DATE",
  name: "RULE_NAME",
  data: "RULE_DATA",
  tenant: "ORGANIZATION",
  assignee: "ASSIGNEE",
  resolution: "RESOLUTION",
  status: "STATUS",
};

export const monitoringQueueFilterSelectors = {
  id: "id",
  name: "ruleName",
  data: "ruleData",
  tenant: "organization",
  assignee: "assignee",
  resolution: "resolution",
  status: "status",
};

export const forMonitoringFilterSelectors = {
  flagId: "flagId",
  assignee: "assignee",
  resolution: "resolutionStatus",
  executionCheck: "executionCheck",
  tenant: "tenant",
};

export const onlyWhiteSpaceRegex = /^\s*$/;

export const periodItems = [
  { value: "DAY", label: "Day" },
  { value: "MONTH", label: "Month" },
  { value: "WEEK", label: "Week" },
];

export const rulesTableTypes = {
  CUSTOM: "custom",
  BASIC: "basic",
};

export const routes = {
  MONITORING_QUEUE: "/monitoring",
  CASES_FOR_MONITORING: "/reports-for-monitoring",
};

export const dateFilterOperations = ["=", "<", ">", "between", "clear"];
