import "./themes/generated/theme.additional.css";
import React, { useEffect, useRef, useState } from "react";
import { HashRouter as Router, useNavigate } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import themes from "devextreme/ui/themes";
import { getLocalizedLabels } from "./services/other";
import { Provider, useDispatch } from "react-redux";
import { setLabels } from "./redux/features/localizedLabels";
import Tenants from "./pages/tenants/tenants";
import Keycloak from "keycloak-js";
import { store } from "./redux/store";
import { createContext } from "react";
import { useContext } from "react";
import jwt_decode from "jwt-decode";
import ActionsRoleProvider from "./contexts/actionsRoleProvider";
import "./general.scss";
import { getUser } from "./services/user";
import { setUser } from "./redux/features/user";
import { useAxiosConfig } from "./services/axios/config";
import { getActionsPermissions } from "./services/actions";
import { setErrorFetchingActions } from "./redux/features/errorFetchingActions";
import { ToastContextProvider } from "./components/toast/toast";

function App() {
  const theme = localStorage.getItem("theme");
  const dispatch = useDispatch();
  const { data: localizedLabels, isLoading } = useQuery(
    "localized-labels",
    getLocalizedLabels,
    { refetchOnWindowFocus: false }
  );
  // const [errorFetchingActions, setErrorFetchingActions] = useState(true);
  const [actionsData, setActionsData] = useState(null);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const screenSizeClass = useScreenSizeClass();
  const [loading, setLoading] = useState(true);
  const [tenancy, setTenancy] = useState("");

  const role = getRole();
  // const role = getRole();

  function getRole() {
    //BO 1st
    // return 'AML_CORDINATOR';

    //BO 2nd
    // return 'AML_MANAGER'

    //return ADMIN

    // return AUDITOR

    if (keycloak.realmAccess?.roles?.includes("GRANT_ADMIN")) {
      return "GRANT_ADMIN";
    } else if (keycloak.realmAccess?.roles?.includes("ADMIN")) {
      return "ADMIN";
    } else if (keycloak.realmAccess?.roles?.includes("AML_CORDINATOR")) {
      return "AML_CORDINATOR";
    } else if (keycloak.realmAccess?.roles?.includes("AML_MANAGER")) {
      return "AML_MANAGER";
    } else if (keycloak.realmAccess?.roles?.includes("AUDITOR")) {
      return "AUDITOR";
    }
  }

  useAxiosConfig();

  useEffect(() => {
    userActionsHandler();
  }, []);

  async function userActionsHandler() {
    try {
      const user = await getUser();
      setTenancy(user.tenantPermissionType);
      dispatch(setUser(user));
    } catch (err) {
      console.error(err);
    }

    try {
      const res = await getActionsPermissions();
      setActionsData(res);
      setLoading(false);
    } catch (err) {
      console.error(err);
      dispatch(setErrorFetchingActions(true));
      navigate("/error");
      setLoading(false);
    }
  }

  if (theme === "dark") {
    themes.current("material.blue.dark");
  }

  if (localizedLabels) {
    dispatch(setLabels(localizedLabels));
  }

  if (isLoading || loading) {
    return <LoadPanel visible={true} />;
  }

  return (
    <ActionsRoleProvider
      role={role}
      defaultActionsData={actionsData}
      tenancy={tenancy}
    >
      <div className={`app ${screenSizeClass}`}>
        <Content />
      </div>
    </ActionsRoleProvider>
  );
}

function initKeycloak(keycloak, setKeycloak, navigate) {
  const tenantSettings = JSON.parse(localStorage.getItem("tenantSettings"));

  const settings = {
    realm: tenantSettings?.realm,
    url: tenantSettings?.server,
    clientId: tenantSettings?.clientId,
  };
  const kc = new Keycloak(settings);

  kc.init({
    onLoad: "login-required",
  })
    .then((authenticated) => {
      if (!authenticated) {
        kc.login();
      }
      if (!keycloak) {
        setKeycloak(kc);
      }
    })
    .catch((err) => console.error(err));

  kc.onAuthSuccess = navigate;
}

const keycloakContext = createContext();
export const useKeycloak = () => useContext(keycloakContext);

function AppWithProviders() {
  const [keycloak, setKeycloak] = useState(null);
  const initializing = useRef(false);
  const navigate = useNavigate();

  if (!keycloak) {
    if (!initializing.current) {
      initializing.current = true;
      initKeycloak(keycloak, setKeycloak, () =>
        navigate(window.location.hash.split("#/").at(-1))
      );
    }
    return null;
  }

  const decodedToken = jwt_decode(keycloak.token);

  return (
    <keycloakContext.Provider
      value={{
        keycloak,
        logout: keycloak.logout,
        token: keycloak.token,
        roles: keycloak.realmAccess.roles,
        name: decodedToken.name,
      }}
    >
      <AuthProvider>
        <Provider store={store}>
          <NavigationProvider>
            <ToastContextProvider>
              <App />
            </ToastContextProvider>
          </NavigationProvider>
        </Provider>
      </AuthProvider>
    </keycloakContext.Provider>
  );
}

export default function Root() {
  const [hasTenant, setHasTenant] = useState(
    !!localStorage.getItem("tenantSettings")
  );
  const { href } = window.location;

  if (href.includes("tenant=false")) {
    const newUrl = href.split("?tenant=false").join("");

    localStorage.removeItem("tenantSettings");
    window.location = newUrl;
  }

  const queryClient = new QueryClient();

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        {/* <AppWithProviders /> */}
        {hasTenant ? (
          <AppWithProviders />
        ) : (
          <Tenants setHasTenant={setHasTenant} />
        )}
      </QueryClientProvider>
    </Router>
  );
}
