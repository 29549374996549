import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as entityService from '../../services/entity';
import * as ruleService from '../../services/rules';
import Loader from '../../components/loader/loader';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Xwrapper } from 'react-xarrows';
import { Button } from 'devextreme-react/button';
import EntitiesDropTarget from './entitiesDropTarget/entitiesDropTarget';
import DataFieldsDropTarget from './dataFieldsDropTarget/dataFieldsDropTarget';
import ConditionsDropTarget from './conditionsDropTarget/conditionsDropTarget';
import { LoadPanel } from 'devextreme-react';
import TestSection from './testSection/testSection';
import { useDispatch, useSelector } from 'react-redux';
import FilterTableSection from './filterTableSection/filterTableSection';
import { setQueries } from '../../redux/features/dashboard/queries';
import LimitSection from './limitSection/limitSection';
import { changeDefaultValue as changeDefaultLimitValue } from '../../redux/features/dashboard/limitSection';
import GroupBy from './groupBy/groupBy';
import Stack from '@mui/material/Stack';
import OrderByDropTarget from './orderBy/orderBy';
import SubQuery from './subQuery/subQuery';
import { Paper } from '@mui/material';
import { idGenerator } from 'generate-custom-id';
import { RuleContext, useRuleContext } from './ruleContext';
import DashboardHeader from './dashboardHeader';

import './ruleDashboard.scss';
import QueryName from './queryName/queryName';
import { updateRuleData } from '../../redux/features/dashboard/rule';
import FunctionsDataContextProvider from './contexts/functionsDataContext';
import QueryContext from './conditionsDropTarget/condition/queryContext';
import { useActionsRoleContext } from '../../contexts/actionsRoleProvider';
import { colors } from '../../utils/colors';
import { useToastContext } from '../../components/toast/toast';
import { getAxiosResponseError } from '../../utils/functions';
import { useCheckPermission } from '../../hooks/useCheckPermission';

function WithQueriesLayout({
    subQueryHandler,
    hasSubQueries,
    isSearch,
    rightId,
    topLevelQuery,
}) {
    const { ruleId, allowRuleEdit } = useRuleContext();

    const theme = useSelector((state) => state.theme);
    const paperStyles = {
        width: '600px',
        position: 'relative',
        padding: '10px',
        background: colors[theme]?.subQueryBackground,
        color: colors[theme]?.textColor,
    };

    return (
        <Paper sx={paperStyles}>
            <Stack
                justifyContent={'space-between'}
                flexDirection={'row'}
                alignItems={'center'}
            >
                <QueryName initialValue={topLevelQuery?.alias} />
                {allowRuleEdit && (
                    <Button
                        stylingMode="outlined"
                        type="default"
                        text="Subquery"
                        onClick={subQueryHandler}
                    />
                )}
            </Stack>
            <EntitiesDropTarget
                forJoin={false}
                hasSubQueries={hasSubQueries}
                queryData={topLevelQuery}
                ruleId={ruleId}
                isSubquery={true}
            />
            <ConditionsDropTarget
                isSubquery={true}
                hasSubQueries={hasSubQueries}
                isSearch={isSearch}
                ruleId={ruleId}
                forJoin={false}
                conditions={topLevelQuery?.conditionGroups}
            />
            <div className="flex-container gap-10">
                <DataFieldsDropTarget
                    isSubquery={true}
                    ruleId={ruleId}
                    dataFields={topLevelQuery?.dataFields}
                />
                <GroupBy
                    isSubquery={true}
                    ruleId={ruleId}
                    isGroupsSection={true}
                    dataFields={topLevelQuery?.dataFields}
                    groups={topLevelQuery?.groups}
                    isTopLevel={true}
                />
            </div>
            <div className="flex-container gap-10 margin-bottom align-end">
                <OrderByDropTarget
                    isSubquery={true}
                    orderByData={topLevelQuery?.orderBy}
                    ruleId={ruleId}
                />
                <LimitSection isSubquery={true} />
            </div>
            <span
                id={rightId}
                className={'right-query-connection-point connection-point '}
            ></span>
        </Paper>
    );
}

function NoQueriesLayout({ isSearch, topLevelQuery }) {
    const { ruleId } = useRuleContext();
    // const topLevelQuery = useSelector((state) => state.queries?.[0]);
    return (
        <>
            <QueryName initialValue={topLevelQuery?.alias} />

            <div className="entities-conditions-section">
                <div className="width-50" style={{ width: '50%' }}>
                    <EntitiesDropTarget
                        queryData={topLevelQuery}
                        ruleId={ruleId}
                    />

                    <DataFieldsDropTarget
                        ruleId={ruleId}
                        dataFields={topLevelQuery?.dataFields}
                    />

                    <GroupBy
                        ruleId={ruleId}
                        dataFields={topLevelQuery?.dataFields}
                        groups={topLevelQuery?.groups}
                    />

                    <OrderByDropTarget
                        orderByData={topLevelQuery?.orderBy}
                        ruleId={ruleId}
                    />

                    <LimitSection />
                </div>
                <ConditionsDropTarget
                    isSearch={isSearch}
                    ruleId={ruleId}
                    conditions={topLevelQuery?.conditionGroups}
                />
            </div>
        </>
    );
}

export default function RuleDashboard({ type }) {
    const navigate = useNavigate();
    let { id } = useParams();
    const [ruleId, setRuleId] = useState(id);

    //TODO refactor and use everywhere only redux state and no rule context (redux was added later)
    const [ruleData, setRuleData] = useState(null);
    const ruleDataRedux = useSelector((state) => state.rule);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const [ruleHistory, setRuleHistory] = useState();
    const [isLoadingRule, setIsLoadingRule] = useState(false);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const [refetchRuleHistory, setRefetchRuleHistory] = useState(0);
    const theme = useSelector((state) => state.theme);
    const [applyFilter, setApplyFilter] = useState(0);
    const rightId = idGenerator('example', 4);
    const topLevelQuery = ruleDataRedux?.queries?.[0];
    const hasSubQueries = !!topLevelQuery?.queries?.length;
    const { role } = useActionsRoleContext();
    const { showErrorToast } = useToastContext();
    const hasEditRulePermission = useCheckPermission('EDIT_RULE');

    useEffect(() => {
        if (ruleId) {
            fetchRuleData();
        }
    }, [ruleId]);

    useEffect(() => {
        if (type === 'search') {
            searchMenuFetchHandler();
        }
    }, []);

    useEffect(() => {
        if (type === 'search') {
            setIsLoadingHistory(false);
        }

        if (ruleId && type !== 'search') {
            ruleService
                .getRuleHistory(ruleId)
                .then((res) => {
                    setIsLoadingHistory(false);
                    setRuleHistory(res);
                })
                .catch((err) => {
                    console.error(err);
                    setRuleHistory(null);
                });
        }
    }, [ruleId, refetchRuleHistory]);

    const {
        data: allEntitiesData,
        error,
        isLoading: loadingEntitiesData,
    } = useQuery('all-entities', entityService.getAll, {
        refetchOnWindowFocus: false,
        retry: 1,
    });

    if (error) {
        navigate('/');
        return null;
    }

    //TODO Change with react query

    async function searchMenuFetchHandler() {
        try {
            const lastActivated = false;
            // const lastActivated = await ruleService.getLastActivatedRule();

            if (lastActivated) {
                dispatch(updateRuleData(lastActivated));
                dispatch(changeDefaultLimitValue(lastActivated.limit?.limit));
                dispatch(setQueries(lastActivated.queries));
                setRuleData(lastActivated);
                setIsLoading(false);
                return;
            }
            const res = await ruleService.create({
                name: '',
                type: 'ADVANCED_SEARCH',
                typeOfRule: 'PTM',
            });
            setRuleId(res?.id);
        } catch (err) {
            console.error(err);
            if (type === 'search') {
                showErrorToast(getAxiosResponseError(err));
            } else {
                navigate('/');
            }
        }
    }

    async function fetchRuleData(isRefetch) {
        // if (!isRefetch) {
        //     setIsLoadingRule(true);
        // }
        setIsLoadingRule(true);

        try {
            const data = await ruleService.getById(ruleId);
            dispatch(updateRuleData(data));
            dispatch(changeDefaultLimitValue(data.limit?.limit));
            dispatch(setQueries(data.queries));
            setRuleData(data);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            navigate('/');
        }
        setIsLoadingRule(false);
    }

    async function addSubQuery() {
        try {
            await ruleService.postSubQuery(topLevelQuery.id);
            fetchRuleData();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    if (!ruleDataRedux) {
        return null;
    }

    return (
        <RuleContext
            rule={ruleData}
            // allowRuleEdit = {false}
            allowRuleEdit={
                (ruleData?.status !== 'ACTIVATED' && hasEditRulePermission) ||
                (ruleData?.status !== 'ACTIVATED' && role === 'GRANT_ADMIN')
            }
            ruleId={ruleData?.id}
            fetchRule={fetchRuleData}
            allEntitiesData={allEntitiesData}
            refetchRule={() => fetchRuleData(true)}
        >
            <FunctionsDataContextProvider>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={isLoadingRule}
                />
                <div className={`rule-dashboard-wrapper ${theme}`}>
                    {!ruleData || loadingEntitiesData ? (
                        <Loader height={'40vh'} />
                    ) : (
                        <DndProvider backend={HTML5Backend}>
                            <Xwrapper>
                                <DashboardHeader
                                    description={ruleData?.description}
                                    hasSubQueries={hasSubQueries}
                                    addSubQuery={addSubQuery}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    isLoadingHistory={isLoadingHistory}
                                    ruleHistory={ruleHistory}
                                    setRefetchRuleHistory={
                                        setRefetchRuleHistory
                                    }
                                    type={type}
                                    setApplyFilter={setApplyFilter}
                                />

                                <div
                                    className={
                                        hasSubQueries
                                            ? 'queries-wrapper gap-30 flex-container'
                                            : ''
                                    }
                                >
                                    <div>
                                        <QueryContext
                                            queryId={topLevelQuery?.id}
                                            queryData={topLevelQuery}
                                        >
                                            {hasSubQueries ? (
                                                <WithQueriesLayout
                                                    subQueryHandler={
                                                        addSubQuery
                                                    }
                                                    isSearch={type === 'search'}
                                                    setIsLoading={setIsLoading}
                                                    rightId={rightId}
                                                    topLevelQuery={
                                                        topLevelQuery
                                                    }
                                                />
                                            ) : (
                                                <NoQueriesLayout
                                                    subQueryHandler={
                                                        addSubQuery
                                                    }
                                                    isSearch={type === 'search'}
                                                    setIsLoading={setIsLoading}
                                                    topLevelQuery={
                                                        topLevelQuery
                                                    }
                                                />
                                            )}
                                        </QueryContext>

                                        {type === 'search' ? (
                                            <FilterTableSection
                                                ruleId={ruleId}
                                                applyFilter={applyFilter}
                                                isInQuery={hasSubQueries}
                                            />
                                        ) : (
                                            <TestSection
                                                ruleId={ruleId}
                                                isInQuery={hasSubQueries}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        {topLevelQuery?.queries?.map((e, i) => (
                                            <SubQuery
                                                queryData={e}
                                                startPointId={rightId}
                                                type={type}
                                                key={i}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </Xwrapper>
                        </DndProvider>
                    )}
                </div>
            </FunctionsDataContextProvider>
        </RuleContext>
    );
}
