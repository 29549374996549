import {
    Accordion,
    Button,
    DataGrid,
    DateBox,
    SelectBox,
    TextBox,
    Tooltip,
} from 'devextreme-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    errorMsg,
    flagStatuses,
    languageLabels,
    resolutionStatuses,
} from '../../utils/vars';
import Loader from '../../components/loader/loader';
import './reports.scss';
import CustomStore from 'devextreme/data/custom_store';
import axios from '../../services/axios/config';
import { Column } from 'devextreme-react/data-grid';
import useGetLabel from '../../hooks/useFindLabel';
import moment from 'moment';
import { setFilters } from '../../redux/features/filters';
import { useEffect } from 'react';
import { generate } from '../../services/report';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { Checkbox } from '@mui/material';
import { useCheckPermission } from '../../hooks/useCheckPermission';
import {
    addId,
    removeId,
    selectAll,
    unSelectAll,
    uncheckHeader,
} from '../../redux/features/reportsSelected';
import { useToastContext } from '../../components/toast/toast';
import { getAxiosResponseError } from '../../utils/functions';

export default function Reports() {
    const [reportType, setReportType] = useState('SINGLE');
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setFilters({}));
        };
    });

    let view = null;

    switch (reportType) {
        case 'SINGLE':
            view = (
                <SingleView
                    reportType={reportType}
                    setReportType={setReportType}
                />
            );
            break;
        case 'MULTI':
            view = (
                <MultiView
                    reportType={reportType}
                    setReportType={setReportType}
                />
            );
            break;
        case 'DAILY':
            view = (
                <DailyView
                    reportType={reportType}
                    setReportType={setReportType}
                />
            );
            break;
        case 'FOR_MONITORING':
            view = (
                <MonitoringView
                    reportType={reportType}
                    setReportType={setReportType}
                />
            );
            break;
        default:
            break;
    }

    return <div className="reports-wrapper">{view}</div>;
}

function SingleView({ reportType, setReportType }) {
    const [showReport, setShowReport] = useState(false);
    const [filtersValues, setFiltersValues] = useState({});
    const lg = useSelector((state) => state.language);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const selectItems = useGetSelectItems();
    const [_, setRerender] = useState(0);
    const dispatch = useDispatch();

    function generateReport() {
        const setFilterValues = { ...filtersValues };
        if (setFilterValues.periodFrom) {
            setFilterValues.periodFrom = moment(
                setFilterValues.periodFrom
            ).format('YYYY-MM-DD');
        }

        if (setFilterValues.periodTo) {
            setFilterValues.periodTo = moment(setFilterValues.periodTo).format(
                'YYYY-MM-DD'
            );
        }

        dispatch(setFilters(setFilterValues));
        setShowReport(true);
        setRerender((oldState) => oldState + 1);
    }

    function updateFilterValue(key, value) {
        setFiltersValues((oldState) => {
            return { ...oldState, [key]: value };
        });
    }

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    function Export() {
        const filters = useSelector((state) => state.filters);
        const { allSelected, selected } = useSelector(
            (state) => state.reportsSelected
        );
        let postData = {
            periodFrom:
                (filters?.periodFrom &&
                    moment(filters?.periodFrom).format('YYYY-MM-DD')) ||
                '',
            periodTo:
                (filters?.periodTo &&
                    moment(filters?.periodTo).format('YYYY-MM-DD')) ||
                '',
            forExport: true,
            type: 'SINGLE',
            clientType: filters?.clientType,
            clientUic: filters?.clientUic,
            cid: filters?.cid,
            name: filters?.name,
            ids: allSelected || !selected.length ? [] : selected,
        };

        return (
            <ExportButtons
                reportType={'SINGLE'}
                setLoading={setDownloadLoading}
                filters={filters}
                disabled={!showReport}
                singlePostData={postData}
            />
        );
    }

    return (
        <div className="reports-wrapper">
            <div className="flex-container">
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    items={selectItems}
                    displayExpr={'label'}
                    valueExpr={'value'}
                    defaultValue={reportType || selectItems[0].value}
                    onValueChange={(v) => {
                        setFilters({});
                        dispatch(setFilters({}));
                        setReportType(v);
                    }}
                />
                <div className="flex-container download-btns">
                    <Export />
                    <Button
                        text="Clear"
                        disabled={
                            filtersValues && !Object.keys(filtersValues).length
                        }
                        onClick={() => {
                            setShowReport(false);
                            setFiltersValues({});
                            dispatch(setFilters({}));
                        }}
                    />
                    <Button
                        text={languageLabels.generateReport[lg]}
                        onClick={generateReport}
                        type="default"
                    />
                </div>
            </div>
            <div className="filters-section">
                <DateBox
                    stylingMode="underlined"
                    width={270}
                    label="From date"
                    value={filtersValues.periodFrom || null}
                    onValueChange={(v) => updateFilterValue('periodFrom', v)}
                />
                <DateBox
                    stylingMode="underlined"
                    width={270}
                    label="To date"
                    value={filtersValues.periodTo || null}
                    onValueChange={(v) => updateFilterValue('periodTo', v)}
                />
                <SelectBox
                    value={filtersValues.clientType || ''}
                    stylingMode="underlined"
                    label="Client type"
                    displayExpr={'label'}
                    valueExpr={'value'}
                    width={270}
                    items={[
                        { value: 'natural', label: 'Natural person' },
                        { value: 'legal', label: 'Legal entity' },
                    ]}
                    onValueChange={(v) => updateFilterValue('clientType', v)}
                />
                <TextBox
                    value={filtersValues.clientUic || ''}
                    stylingMode="underlined"
                    width={270}
                    label="Client UIC (EGN / Company number)"
                    onInput={(ev) =>
                        updateFilterValue('clientUic', ev.event.target.value)
                    }
                />
                <TextBox
                    stylingMode="underlined"
                    value={filtersValues.cid || ''}
                    width={270}
                    label="ID/Offer ID"
                    onInput={(ev) =>
                        updateFilterValue('cid', ev.event.target.value)
                    }
                />
                <TextBox
                    stylingMode="underlined"
                    value={filtersValues.name || ''}
                    width={270}
                    label="Name / Company name"
                    onInput={(ev) =>
                        updateFilterValue('name', ev.event.target.value)
                    }
                />
            </div>
            <SingleReportTable
                show={showReport}
                setDownloadLoading={setDownloadLoading}
            />
        </div>
    );
}

function MultiView({ reportType, setReportType }) {
    const [filtersValues, setFiltersValues] = useState({});
    const lg = useSelector((state) => state.language);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const selectItems = useGetSelectItems();
    const [refetch, setRefetch] = useState(0);
    const [reportData, setReportData] = useState(null);
    const [page, setPage] = useState(0);
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const size = 10;
    const [pages, setPages] = useState(null);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');
    const { showErrorToast } = useToastContext();

    useEffect(() => {
        if (refetch) {
            generateReport(null, true);
        }
    }, [refetch]);

    async function generateReport(_ev, isRefetch) {
        if (!isRefetch) {
            setPage(0);
        }
        setIsLoadingReport(true);
        setReportData(null);

        const postData = {
            periodFrom:
                (filtersValues?.periodFrom &&
                    moment(filtersValues.periodFrom).format('YYYY-MM-DD')) ||
                '',
            periodTo:
                (filtersValues?.periodFrom &&
                    moment(filtersValues.periodTo).format('YYYY-MM-DD')) ||
                '',
            type: 'MULTI',
            clientStatus: filtersValues?.clientStatus || '',
            changedLevelRisk: filtersValues?.changedLevelRisk || '',
            typeOfRule: filtersValues?.typeOfRule || '',
            ruleName: filtersValues?.ruleName || '',
            caseStatus: filtersValues?.caseStatus || '',
            resolutionStatus: filtersValues?.resolutionStatus || '',
            forExport: false,
            riskLevel: filtersValues?.riskLevel || '',
        };

        try {
            const data = await generate(isRefetch ? page : 0, size, postData);
            setPages(data?.elements / size);

            setReportData(data.data);
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
        setIsLoadingReport(false);
    }

    function updateFilterValue(key, value) {
        setFiltersValues((oldState) => {
            return { ...oldState, [key]: value };
        });
    }

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    function pagingHandler(newPage) {
        setPage(newPage);
        setRefetch((oldState) => oldState + 1);
    }

    return (
        <div className="reports-wrapper">
            <div className="flex-container">
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    items={selectItems}
                    displayExpr={'label'}
                    valueExpr={'value'}
                    defaultValue={reportType || selectItems[0].value}
                    onValueChange={(v) => {
                        setFilters({});
                        setReportType(v);
                    }}
                />
                <div className="flex-container download-btns">
                    {exportPermission && (
                        <ExportButtons
                            filters={filtersValues}
                            disabled={!reportData || !reportData?.length}
                            setLoading={setDownloadLoading}
                            reportType={reportType}
                        />
                    )}
                    <Button
                        text="Clear"
                        disabled={
                            filtersValues && !Object.keys(filtersValues).length
                        }
                        onClick={() => {
                            setReportData(null);
                            setFiltersValues({});
                        }}
                    />
                    <Button
                        text={languageLabels.generateReport[lg]}
                        onClick={generateReport}
                        type="default"
                    />
                </div>
            </div>
            <div className="filters-section">
                <DateBox
                    stylingMode="underlined"
                    width={270}
                    label="From date"
                    value={filtersValues.periodFrom || null}
                    onValueChange={(v) => updateFilterValue('periodFrom', v)}
                />
                <DateBox
                    stylingMode="underlined"
                    width={270}
                    label="To date"
                    value={filtersValues.periodTo || null}
                    onValueChange={(v) => updateFilterValue('periodTo', v)}
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.clientStatus || ''}
                    items={[
                        { label: 'Active', value: 'active' },
                        { label: 'Inactive', value: 'inactive' },
                    ]}
                    label="Client status"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) => updateFilterValue('clientStatus', v)}
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.changedLevelRisk || ''}
                    items={[
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' },
                    ]}
                    label="Changed level risk"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) =>
                        updateFilterValue('changedLevelRisk', v)
                    }
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.typeOfRule || ''}
                    items={[
                        { label: 'RTM', value: 'RTM' },
                        { label: 'PTM', value: 'PTM' },
                    ]}
                    label="Type of rule"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) => updateFilterValue('typeOfRule', v)}
                />
                <TextBox
                    width={270}
                    stylingMode="underlined"
                    label="Rule name"
                    value={filtersValues.ruleName || ''}
                    onInput={(ev) =>
                        updateFilterValue('ruleName', ev.event.target.value)
                    }
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.caseStatus || ''}
                    items={[
                        { label: 'Pending', value: 'PENDING' },
                        { label: 'In progress', value: 'IN_PROGRESS' },
                        { label: 'Completed', value: 'COMPLETED' },
                        { label: 'Escalated', value: 'ESCALATED' },
                    ]}
                    label="Case status"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) => updateFilterValue('caseStatus', v)}
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.resolutionStatus || ''}
                    items={[
                        {
                            label: 'Document Enquiry',
                            value: 'DOCUMENT_ENQUIRY',
                        },
                        {
                            label: 'For Investigation',
                            value: 'FOR_INVESTIGATION',
                        },
                        { label: 'False Positive', value: 'FALSE_POSITIVE' },
                        {
                            label: 'Termination of the contract',
                            value: 'TERMINATION_OF_CONTRACT',
                        },
                        {
                            label: 'Stop the operation',
                            value: 'STOP_OPERATION',
                        },
                        { label: 'For Reporting', value: 'FOR_REPORTING' },
                        { label: 'For Monitoring', value: 'FOR_MONITORING' },
                    ]}
                    label="Resolution status"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) =>
                        updateFilterValue('resolutionStatus', v)
                    }
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.riskLevel || ''}
                    items={[
                        { label: 'Low', value: 'low' },
                        { label: 'High', value: 'high' },
                        { label: 'Medium', value: 'medium' },
                        { label: 'All', value: 'all' },
                    ]}
                    label="Risk level"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) => updateFilterValue('riskLevel', v)}
                />
            </div>
            {!!reportData && !reportData.length && (
                <p className="no-data">No data</p>
            )}
            {isLoadingReport && <Loader height={'300px'} />}
            {!!reportData && !!reportData.length && (
                <AccordionComponent dataSource={reportData} />
            )}
            {reportData && !!reportData.length && pages && pages > 1 && (
                <Stack
                    sx={{ marginTop: '10px' }}
                    flexDirection={'row'}
                    className="pagination-wrapper"
                    justifyContent={'end'}
                >
                    <Pagination
                        page={page + 1}
                        count={pages}
                        hideNextButton={true}
                        hidePrevButton={true}
                        onChange={(_ev, page) => pagingHandler(page - 1)}
                    />
                </Stack>
            )}
        </div>
    );
}

function AccordionComponent({ dataSource }) {
    const selectedItems = [];

    function MultiTable(data) {
        return (
            <div>
                <DataGrid
                    paging={{ enabled: true, pageSize: 5 }}
                    allowColumnResizing={true}
                    keyExpr={'amlRisk'}
                    dataSource={data.flags}
                >
                    <Column
                        allowSorting={false}
                        caption="Type of rule"
                        dataField={'typeOfRule'}
                    />
                    <Column
                        allowSorting={false}
                        caption="Rule name"
                        dataField={'ruleName'}
                    />
                    <Column
                        allowSorting={false}
                        caption="Case status"
                        dataField={'caseStatus'}
                    />
                    <Column
                        allowSorting={false}
                        caption="Resolution status"
                        dataField={'resolutionStatus'}
                    />
                    <Column
                        allowSorting={false}
                        caption="AML risk"
                        dataField={'amlRisk'}
                    />
                    <Column
                        allowSorting={false}
                        caption="Amount"
                        dataField={'amount'}
                    />
                </DataGrid>
            </div>
        );
    }

    return (
        <div className="accordion-wrapper">
            <Accordion
                selectedItems={selectedItems}
                itemTitleRender={(data) => `UIC - ${data.uic}`}
                dataSource={dataSource}
                itemRender={MultiTable}
            ></Accordion>
        </div>
    );
}

function DailyView({ reportType, setReportType }) {
    // const [filtersValues, setFiltersValues] = useState({});
    const lg = useSelector((state) => state.language);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const selectItems = useGetSelectItems();
    const dispatch = useDispatch();
    const [hasFetchError, setHasFetchError] = useState(true);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');

    const customStore = new CustomStore({
        key: '',
        load(loadOptions) {
            const skip = loadOptions.skip;
            const take = loadOptions.take;
            const page = skip / take;
            return axios
                .post(`report?page=${page}&size=${take}`, {
                    type: 'DAILY',
                    forExport: false,
                    reportExportType: 'PDF',
                })
                .then((res) => {
                    const data = res.data;
                    setHasFetchError(false);
                    return {
                        data: data.data,
                        totalCount: data.elements,
                    };
                })
                .catch((err) => {
                    console.error(err);
                    setHasFetchError(true);
                    throw errorMsg;
                });
        },
    });

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    return (
        <div className="reports-wrapper">
            <div className="flex-container">
                <SelectBox
                    width={270}
                    stylingMode="underlined"
                    items={selectItems}
                    displayExpr={'label'}
                    valueExpr={'value'}
                    defaultValue={reportType || selectItems[0].value}
                    onValueChange={(v) => {
                        dispatch(setFilters({}));
                        setReportType(v);
                    }}
                />
                <div className="flex-container download-btns">
                    {exportPermission && (
                        <ExportButtons
                            hasFilters={true}
                            filters={{}}
                            disabled={hasFetchError}
                            setLoading={setDownloadLoading}
                            reportType={reportType}
                        />
                    )}
                    {/* <Button
                        text={languageLabels.generateReport[lg]}
                        type="default"
                    /> */}
                </div>
            </div>
            <DataGrid
                allowColumnResizing={true}
                dataSource={customStore}
                remoteOperations={{ paging: true }}
            >
                <Column
                    allowSorting={false}
                    caption={'CID'}
                    dataField={'cid'}
                />
                <Column
                    allowSorting={false}
                    caption={'UIC'}
                    dataField={'uic'}
                />
                <Column
                    allowSorting={false}
                    caption={'Product'}
                    dataField={'product'}
                />
                <Column
                    allowSorting={false}
                    caption={'Limit'}
                    dataField={'limit'}
                />
                <Column
                    allowSorting={false}
                    caption={'Sum above one hundred'}
                    dataField={'sumAboveOnehundred'}
                />
                <Column
                    allowSorting={false}
                    caption={'Date'}
                    dataField={'date'}
                    dataType={'datetime'}
                />
                <Column
                    allowSorting={false}
                    caption={'Status'}
                    dataField={'status'}
                />
                <Column
                    allowSorting={false}
                    caption={'CID exists'}
                    dataField={'cidExists'}
                />
            </DataGrid>
        </div>
    );
}

function MonitoringView({ reportType, setReportType }) {
    const lg = useSelector((state) => state.language);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const selectItems = useGetSelectItems();
    const dispatch = useDispatch();
    const [hasFetchError, setHasFetchError] = useState(true);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');

    const customStore = new CustomStore({
        key: '',
        load(loadOptions) {
            const skip = loadOptions.skip;
            const take = loadOptions.take;
            const page = skip / take;
            return axios
                .post(`report?page=${page}&size=${take}`, {
                    type: 'FOR_MONITORING',
                    forExport: false,
                    reportExportType: 'PDF',
                })
                .then((res) => {
                    const data = res.data;
                    setHasFetchError(false);
                    return {
                        data: data.data,
                        totalCount: data.elements,
                    };
                })
                .catch((err) => {
                    console.error(err);
                    setHasFetchError(true);
                    throw errorMsg;
                });
        },
    });

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    return (
        <div className="reports-wrapper">
            <div className="flex-container">
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    items={selectItems}
                    displayExpr={'label'}
                    valueExpr={'value'}
                    defaultValue={reportType || selectItems[0].value}
                    onValueChange={(v) => {
                        dispatch(setFilters({}));
                        setReportType(v);
                    }}
                />
                <div className="flex-container download-btns">
                    {exportPermission && (
                        <ExportButtons
                            hasFilters={true}
                            disabled={hasFetchError}
                            setLoading={setDownloadLoading}
                            reportType={reportType}
                        />
                    )}
                    {/* <Button
                        text={languageLabels.generateReport[lg]}
                        type="default"
                    /> */}
                </div>
            </div>
            <DataGrid
                allowColumnResizing={true}
                dataSource={customStore}
                remoteOperations={{ paging: true }}
            >
                <Column
                    allowSorting={false}
                    caption="Flag ID"
                    dataField={'flagId'}
                    alignment={'left'}
                />
                <Column
                    allowSorting={false}
                    caption="Resolution"
                    cellRender={(data) =>
                        resolutionStatuses[data.data?.resolution]
                    }
                />
                <Column
                    allowSorting={false}
                    caption="Resolution date"
                    dataField={'resolutionDate'}
                    dataType={'datetime'}
                />
                <Column
                    allowSorting={false}
                    caption="Due date"
                    dataField={'dueDate'}
                    dataType={'datetime'}
                />
                <Column
                    allowSorting={false}
                    caption="Execution check"
                    dataField={'executionCheck'}
                />
                <Column
                    allowSorting={false}
                    caption="Tenant"
                    dataField={'tenant'}
                />
            </DataGrid>
        </div>
    );
}

function useGetSelectItems() {
    const lg = useSelector((state) => state.language);

    const selectItems = [
        { label: useGetLabel('SingleClientReportLabel', lg), value: 'SINGLE' },
        { label: useGetLabel('MultipleClientReportLabel', lg), value: 'MULTI' },
        { label: 'Daily report for overlimit', value: 'DAILY' },
        {
            label: 'Report for cases for monitoring',
            value: 'FOR_MONITORING',
        },
    ];

    return selectItems;
}

function ExportButtons({
    hasFilters,
    reportType,
    setLoading,
    disabled,
    filters,
    id,
    personId,
    singlePostData,
}) {
    const [excelTooltipVisible, setExcelTooltipVisible] = useState(false);
    const [pdfTooltipVisible, setPdfTooltipVisible] = useState(false);
    const lg = useSelector((state) => state.language);
    const { showErrorToast } = useToastContext();

    function exportHandler(type) {
        setLoading(true);
        let postData = {};

        switch (reportType) {
            case 'SINGLE':
                postData = singlePostData;
                postData.reportExportType = type;
                break;
            case 'MULTI':
                postData = {
                    type: 'MULTI',
                    clientStatus: filters?.clientStatus || '',
                    changedLevelRisk:
                        filters?.changedLevelRisk !== undefined
                            ? filters?.changedLevelRisk
                            : '',
                    typeOfRule: filters?.typeOfRule || '',
                    ruleName: filters?.ruleName || '',
                    caseStatus: filters?.caseStatus || '',
                    resolutionStatus: filters?.resolutionStatus || '',
                    forExport: true,
                    reportExportType: type,
                };
                break;
            case 'DAILY':
                postData = {
                    type: 'DAILY',
                    forExport: true,
                    reportExportType: type,
                };
                break;
            case 'FOR_MONITORING':
                postData = {
                    type: 'FOR_MONITORING',
                    forExport: true,
                    reportExportType: type,
                };
                break;
            default:
                break;
        }
        const attribute = type === 'PDF' ? 'file.pdf' : 'file.xlsx';

        const url =
            reportType === 'SINGLE'
                ? 'report/single/export/checked'
                : '/report';

        axios({
            url,
            method: 'POST',
            responseType: 'blob',
            data: postData,
        })
            .then((response) => {
                setLoading(false);
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', attribute);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.error(err);
                showErrorToast(getAxiosResponseError(err));
                setLoading(false);
            });
    }

    return (
        <>
            <div
                // style={excelBtnStyle}
                className={
                    hasFilters
                        ? 'no-filter-excel download-excel'
                        : 'download-excel'
                }
                onMouseEnter={() => {
                    if (!disabled) {
                        setExcelTooltipVisible(true);
                    }
                }}
                onMouseLeave={() => setExcelTooltipVisible(false)}
            >
                <Button
                    disabled={disabled}
                    id="export-excel"
                    icon="exportxlsx"
                    stylingMode="outlined"
                    onClick={() => exportHandler('EXCEL')}
                />
            </div>
            <Tooltip
                target="#export-excel"
                visible={excelTooltipVisible}
                hideOnOutsideClick={false}
            >
                <div>{languageLabels.exportExcel[lg]}</div>
            </Tooltip>
            <div
                // style={pdfBtnStyle}
                className={
                    hasFilters ? 'no-filter-pdf download-pdf' : 'download-pdf'
                }
                onMouseEnter={() => {
                    if (!disabled) {
                        setPdfTooltipVisible(true);
                    }
                }}
                onMouseLeave={() => setPdfTooltipVisible(false)}
            >
                <Button
                    disabled={disabled}
                    onClick={() => exportHandler('PDF')}
                    icon="exportpdf"
                    stylingMode="outlined"
                    id="export-pdf"
                />
            </div>
            <Tooltip
                target="#export-pdf"
                visible={pdfTooltipVisible}
                hideOnOutsideClick={false}
            >
                <div>{languageLabels.exportPdf[lg]}</div>
            </Tooltip>
        </>
    );
}

const SingleReportTable = React.memo(function SingleReportTable({ show }) {
    const filters = useSelector((state) => state.filters);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');

    const [downloading, setDownloading] = useState(false);
    if (!show) {
        return null;
    }

    if (downloading) {
        return <Loader height={'300px'} />;
    }

    const postData = {
        type: 'SINGLE',
        clientType: filters?.clientType || '',
        clientUic: filters?.clientUic || '',
        cid: filters?.cid || '',
        name: filters?.name || '',
        periodFrom:
            (filters?.periodFrom &&
                moment(filters?.periodFrom).format('YYYY-MM-DD')) ||
            '',
        periodTo:
            (filters?.periodTo &&
                moment(filters?.periodTo).format('YYYY-MM-DD')) ||
            '',
        forExport: false,
    };

    const customStore = new CustomStore({
        key: '',
        load(loadOptions) {
            const skip = loadOptions.skip;
            const take = loadOptions.take;
            const page = skip / take;
            return axios
                .post(`report?page=${page}&size=${take}`, postData)
                .then((res) => {
                    const data = res.data;
                    return {
                        data: data.data,
                        totalCount: data.elements,
                    };
                    // return mapped;
                })
                .catch((err) => {
                    console.error(err);
                    throw errorMsg;
                });
        },
    });

    return (
        <div className="table-wrapper">
            <DataGrid
                allowColumnResizing={true}
                keyExpr={'id'}
                remoteOperations={{ paging: true }}
                dataSource={customStore}
            >
                <Column
                    allowSorting={false}
                    caption="Type of rule"
                    dataField={'typeOfRule'}
                />
                <Column
                    allowSorting={false}
                    caption="Rule name"
                    dataField={'ruleName'}
                />
                <Column
                    allowSorting={false}
                    caption="Case status"
                    dataField={'caseStatus'}
                    cellRender={(data) =>
                        flagStatuses[data.data?.caseStatus] || ''
                    }
                />
                <Column
                    allowSorting={false}
                    caption="Link to case"
                    dataField={'linkToCase'}
                    cellRender={(data) => {
                        return (
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={data.data?.linkToCase}
                            >
                                {data.data?.linkToCase}
                            </a>
                        );
                    }}
                />
                <Column
                    allowSorting={false}
                    caption="Username and role"
                    dataField={'userNameAndRole'}
                />
                <Column
                    allowSorting={false}
                    dataType={'datetime'}
                    caption="Date of case status"
                    dataField={'dateOfCaseStatus'}
                />
                <Column
                    allowSorting={false}
                    dataType={'datetime'}
                    caption="Date of resolution status"
                    dataField={'dateOfResolutionStatus'}
                />
                {exportPermission && (
                    <Column
                        alignment="center"
                        caption="Export"
                        allowSorting={false}
                        headerCellComponent={(data) => (
                            <HeaderCheckbox data={data.data} />
                        )}
                        // cellComponent={(data) => <Export data={data.data} />}
                        cellComponent={(data) => (
                            <CellCheckbox id={data.data.data.id} />
                        )}
                    />
                )}
            </DataGrid>
        </div>
    );
});

function HeaderCheckbox() {
    const { allSelected } = useSelector((state) => state.reportsSelected);
    const dispatch = useDispatch();

    return (
        <Checkbox
            checked={allSelected}
            onChange={(ev) => {
                if (ev.target.checked) {
                    dispatch(selectAll());
                } else {
                    dispatch(unSelectAll());
                    dispatch(uncheckHeader());
                }
            }}
        />
    );
}

function CellCheckbox({ id }) {
    const { allSelected, selected } = useSelector(
        (state) => state.reportsSelected
    );
    const dispatch = useDispatch();
    const index = selected.findIndex((e) => e === id);
    let isSelected = false;

    useEffect(() => {
        if (allSelected && index === -1) {
            dispatch(addId(id));
        }
    }, [allSelected]);

    if (allSelected) {
        isSelected = true;
    } else {
        if (index !== -1) {
            isSelected = true;
        }
    }

    function checkboxHandler(checked) {
        if (checked) {
            dispatch(addId(id));
        } else {
            if (allSelected) {
                dispatch(uncheckHeader());
            }
            dispatch(removeId(id));
        }
    }

    return (
        <Checkbox
            checked={isSelected}
            onChange={(ev) => checkboxHandler(ev.target.checked)}
        />
    );
}
