import { Button, DataGrid, LoadIndicator, Tooltip } from "devextreme-react";
import { Column, Paging } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getTestTableColumns } from "../../../services/rules";
import axios from "../../../services/axios/config";
import { useSelector } from "react-redux";
import { errorMsg, languageLabels } from "../../../utils/vars";
import { addFlag } from "../../../services/flag";
import { useToastContext } from "../../../components/toast/toast";
import { getAxiosResponseError } from "../../../utils/functions";

export default function TestSection({ isInQuery, ruleId }) {
  const [showTable, setShowTable] = useState(false);
  const lg = useSelector((store) => store.language);
  const theme = useSelector((state) => state.theme);
  const { showErrorToast } = useToastContext();
  const [showTooltip, setShowTooltip] = useState(false);
  const [refetchColumns, setRefetchColumns] = useState(0);

  let wrapperClasses = "test-section-wrapper";

  wrapperClasses += theme === "dark" ? " paper-dark-theme" : " paper";

  if (isInQuery) {
    wrapperClasses += " query-table-wrapper";
  }
  async function getQuery() {
    try {
      setShowTable(true);
      setRefetchColumns((oldState) => oldState + 1);
    } catch (err) {
      console.error(err);
      showErrorToast(getAxiosResponseError(err));
    }
  }

  function exportHandler() {
    axios({
      url: `search/advanced/export?ruleId=${ruleId}&type=EXCEL`,
      method: "POST",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
        showErrorToast(getAxiosResponseError(err));
      });
  }

  return (
    <div className={wrapperClasses}>
      <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
        <Button
          stylingMode="outlined"
          type="default"
          text={languageLabels.test[lg]}
          onClick={getQuery}
        />
        {showTable ? (
          <div className="export-btn-wrapper">
            <div
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <Button
                id="export-btn"
                stylingMode="outlined"
                icon="exportxlsx"
                onClick={exportHandler}
              />
            </div>
            <Tooltip visible={showTooltip} target={"#export-btn"}>
              Export in excel
            </Tooltip>
          </div>
        ) : null}
      </div>
      {showTable && <TestTable refetchColumns={refetchColumns} />}
    </div>
  );
}

function TestTable({ refetchColumns }) {
  const { id } = useParams();
  const {
    data: columnsData,
    isLoading,
    refetch,
    isFetching,
  } = useQuery("testColumnsData", () => getTestTableColumns(id), {
    refetchOnWindowFocus: false,
    retry: 0,
  });
  const [customStore, setCustomStore] = useState();
  const { showErrorToast, showSuccessToast } = useToastContext();

  useEffect(() => {
    refetch();
  }, [refetchColumns]);

  useEffect(() => {
    const store = new CustomStore({
      key: "",
      load(loadOptions) {
        const skip = loadOptions.skip;
        const take = loadOptions.take;
        const page = skip / take;

        return axios
          .get(`rule/columns/data/${id}?page=${page}&size=${take}`)
          .then((res) => {
            const data = res.data;
            const mapped = data.columnData.map((e) => e.data);
            return {
              data: mapped,
              totalCount: data.totalCount,
            };
          })
          .catch((err) => {
            throw err.response?.data?.message || errorMsg;
          });
      },
    });
    setCustomStore(store);
  }, []);

  if (isLoading || isFetching || !customStore) {
    return <LoadIndicator />;
  }
  const columns = columnsData?.map((c, i) => (
    <Column
      allowResizing={true}
      allowSorting={false}
      dataType={c.includes("date") ? "datetime" : "string"}
      caption={c}
      dataField={c}
      key={i}
    />
  ));

  if (columns) {
    columns.push(
      <Column
        allowResizing={true}
        alignment={"center"}
        cellRender={(data) => {
          return (
            <Button
              type="default"
              stylingMode="outlined"
              text="Add flag"
              onClick={() => addFlagHandler(data.data)}
            />
          );
        }}
        key={-1}
      />
    );
  }

  async function addFlagHandler(data) {
    const mappedData = Object.keys(data).map((key) => {
      return { key: key, value: data[key] };
    });
    try {
      await addFlag({ ruleId: id, data: mappedData });
      showSuccessToast("Flag successfully added.");
    } catch (err) {
      console.error(err);
      showErrorToast(getAxiosResponseError(err));
    }
  }

  return (
    <>
      <DataGrid
        keyExpr={"id"}
        remoteOperations={{
          paging: true,
        }}
        dataSource={customStore}
      >
        {columns}
        <Paging pageSize={10} />
      </DataGrid>
    </>
  );
}
