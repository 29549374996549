import { DateBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import React, { memo, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { deleteField, updateField } from '../../../../../services/rules';
import moment from 'moment';
import { useConditionContext } from '../conditionsContext';
import { useRuleContext } from '../../../ruleContext';
import NameEdit from '../NameEdit/nameEdit';
import { addHoverField } from '../../../../../redux/features/dashboard/rule';
import { useQueryContext } from '../queryContext';
import Formula from '../formula/formula';
import { useFieldsGroupContext } from '../fieldsGroupContext';
import MoreActions from './moreActions';
import FunctionWrapper from './fieldFunctionWrapper';
import { useGetDefaultPutData } from '../../../../../hooks/useGetDefaultPostData';

const RightField = memo(function RightField() {
    const { fieldsGroupData } = useFieldsGroupContext();
    const fieldData = fieldsGroupData?.rightPart;
    const entityData = fieldData?.entityField;
    const { allowRuleEdit } = useRuleContext();
    // const [fieldType, setFieldType] = useState(fieldData.type);
    const fieldType = fieldData?.type;

    const leftType = fieldsGroupData?.leftPart?.entityField?.field?.type;
    const savedFunctions = entityData?.entityFieldFunction;
    const [nameEditMode, setNameEditMode] = useState(false);

    let content = null;

    // console.log('fieldData', fieldData);

    // useEffect(() => {
    //     setFieldType(fieldData.type);
    // }, [fieldData.type]);

    switch (fieldType) {
        case 'FORMULA':
            const dropField = <DropField fieldType={fieldType} />;
            content = (
                <Formula
                    left={false}
                    savedFunctions={savedFunctions}
                    nameEditMode={nameEditMode}
                    dropField={dropField}
                    nameEdit={
                        <NameEdit
                            setNameEditMode={setNameEditMode}
                            defaultValue={
                                entityData?.name || entityData?.field?.name
                            }
                            id={entityData?.id}
                        />
                    }
                    functionWrapper={
                        <FunctionWrapper
                            content={dropField}
                            functionData={savedFunctions}
                            id={entityData?.id}
                        />
                    }
                />
            );
            break;

        case 'FIELD':
            content = (
                <EntityField
                    nameEditMode={nameEditMode}
                    setNameEditMode={setNameEditMode}
                    rightFieldId={entityData?.id}
                    fieldType={fieldType}
                />
            );
            break;

        case 'VALUE':
            content = <InputField fieldType={fieldType} />;
            break;
        case 'BOOL':
            content = <YesNo fieldType={fieldType} />;
            break;
        case 'DATE':
            content = <DateField fieldType={fieldType} />;
            break;
        default:
            break;
    }

    let showFunctionWrapper =
        savedFunctions && fieldType === 'FIELD' && !nameEditMode;

    if (leftType === 'BOOL' || !!fieldData?.sign) {
        showFunctionWrapper = false;
    }

    return (
        <div className="field-wrapper">
            {leftType !== 'BOOL' && allowRuleEdit && (
                <MoreActions
                    id={entityData?.id}
                    setNameEditMode={setNameEditMode}
                    // setFieldType={setFieldType}
                    showFunctionsPopover={
                        !savedFunctions && fieldType !== 'VALUE' && !!entityData
                    }
                    fieldType={fieldType}
                    showChangeAlias={fieldType === 'FIELD' && !!entityData}
                    isRightField={true}
                    allowValueEntry={leftType !== 'DATE'}
                    allowDateEntry={leftType === 'DATE'}
                />
            )}

            {showFunctionWrapper ? (
                <FunctionWrapper
                    content={content}
                    functionData={savedFunctions}
                    id={entityData.id}
                />
            ) : (
                content
            )}
        </div>
    );
});

function EntityField({
    nameEditMode,
    setNameEditMode,
    rightFieldId,
    fieldType,
}) {
    const { fieldsGroupData } = useFieldsGroupContext();
    const entityData = fieldsGroupData?.rightPart?.entityField;

    return (
        <>
            {nameEditMode ? (
                <NameEdit
                    setNameEditMode={setNameEditMode}
                    defaultValue={entityData?.name || entityData?.field?.name}
                    id={rightFieldId}
                    fieldType={fieldType}
                />
            ) : (
                <DropField fieldType={fieldType} />
            )}
        </>
    );
}

function DropField({ fieldType }) {
    const { fieldsGroupData } = useFieldsGroupContext();
    const fieldData = fieldsGroupData?.rightPart;
    const entityData = fieldData?.entityField;
    const dispatch = useDispatch();
    const value = entityData?.name || entityData?.field?.name;
    const { refetchRule, allowRuleEdit } = useRuleContext();
    const { forJoin, currentGroupId } = useConditionContext();
    const { queryId } = useQueryContext();
    const getDefaultPutData = useGetDefaultPutData();
    const defaultData = getDefaultPutData();
    const putData = { ...defaultData };

    const [_collectedProps, drop] = useDrop(() => ({
        accept: 'entity-drop-target',
        drop: (item, monitor) => {
            dropEntityFieldHandler(item.displayedEntityId, item.data);
        },
        hover: (_item, monitor) => {
            dispatch(
                addHoverField({
                    queryId,
                    conditionGroupId: currentGroupId,
                    type: forJoin ? 'joinGroups' : 'conditionGroups',
                    fieldType: 'rightPart',
                    fieldId: fieldsGroupData.id,
                    hovered: true,
                })
            );
        },
    }));

    async function dropEntityFieldHandler(rightDisplayedEntityId, field) {
        try {
            putData.type = fieldType;
            putData.rightFieldId = field?.id;
            putData.rightDisplayedEntityId = rightDisplayedEntityId;

            await updateField(putData);
            refetchRule();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            {!value || fieldData?.hovered ? (
                <div ref={drop} className="entitiy-drop-field">
                    {allowRuleEdit && 'Drop entity here'}
                </div>
            ) : (
                <div ref={drop} style={{ flexGrow: 1 }}>
                    <TextBox
                        stylingMode="outlined"
                        disabled={true}
                        value={value}
                    />
                </div>
            )}
        </>
    );
}

function DateField({ fieldType }) {
    const { fieldsGroupData } = useFieldsGroupContext();
    const fieldData = fieldsGroupData?.rightPart;
    const getDefaultPutData = useGetDefaultPutData();

    const [conditionInterval, setConditionInterval] = useState(
        fieldData?.conditionInterval || 'DAY_BEFORE_NOW'
    );
    const { refetchRule, allowRuleEdit } = useRuleContext();
    const dateDefaultValue =
        fieldData?.conditionInterval === 'DAY_OF_MONTH' && fieldData?.value
            ? new Date(fieldData?.value)
            : null;
    const numberDefaultValue =
        fieldData?.conditionInterval !== 'DAY_OF_MONTH' && !!fieldData?.value
            ? Number(fieldData?.value)
            : null;

    const [numberValue, setNumberValue] = useState(numberDefaultValue);

    const datefieldSelectItems = [
        { label: 'Days before now', value: 'DAY_BEFORE_NOW' },
        { label: 'Years before now', value: 'YEAR_BEFORE_NOW' },
        { label: 'Months before now', value: 'MONTH_BEFORE_NOW' },
        { label: 'Day of month', value: 'DAY_OF_MONTH' },
    ];

    async function updateFieldHandler(value, conditionIntervalValue) {
        const defaultData = getDefaultPutData();
        const putData = { ...defaultData };

        if (conditionIntervalValue) {
            putData.conditionInterval =
                conditionIntervalValue || conditionInterval;
        }

        putData.value = value || null;

        putData.type = fieldType;

        try {
            await updateField(putData);
            refetchRule();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="date-field-wrapper">
            {conditionInterval === 'DAY_OF_MONTH' && (
                <DateBox
                    disabled={!allowRuleEdit}
                    onValueChange={(v) =>
                        updateFieldHandler(moment(v).format('YYYY-MM-DD'))
                    }
                    stylingMode="underlined"
                    defaultValue={dateDefaultValue}
                />
            )}
            {conditionInterval !== 'DAY_OF_MONTH' &&
                conditionInterval !== null && (
                    <NumberBox
                        disabled={!allowRuleEdit}
                        stylingMode="underlined"
                        width={50}
                        onChange={(e) => {
                            console.log(e.event.currentTarget);
                            setNumberValue(e.event.currentTarget.value);
                            updateFieldHandler(e.event.currentTarget.value);
                        }}
                        defaultValue={numberDefaultValue}
                    />
                )}
            <SelectBox
                disabled={!allowRuleEdit}
                width={'200px'}
                dropDownOptions={{width:200}}
                items={datefieldSelectItems}
                value={conditionInterval || ''}
                displayExpr={'label'}
                valueExpr={'value'}
                stylingMode="underlined"
                onValueChange={(v) => {
                    setConditionInterval(v);

                    if (v !== 'DAY_OF_MONTH') {
                        updateFieldHandler(numberValue, v);
                    } else {
                        setNumberValue(null);
                        updateFieldHandler(null, v);
                    }
                }}
            />
        </div>
    );
}

function YesNo({ fieldType }) {
    const { refetchRule, allowRuleEdit } = useRuleContext();
    const { fieldsGroupData } = useFieldsGroupContext();
    const getDefaultPutData = useGetDefaultPutData();

    async function changeHandler(value) {
        const defaultData = getDefaultPutData();
        const putData = { ...defaultData };

        console.log('value', value);
        putData.value = value === 'Yes' ? true : false;
        putData.type = fieldType;

        try {
            await updateField(putData);
            refetchRule();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <SelectBox
            disabled={!allowRuleEdit}
            stylingMode="underlined"
            defaultValue={
                fieldsGroupData?.rightPart?.value === 'true'  ? 'Yes' : 'No'
            }
            width={100}
            onValueChange={changeHandler}
            items={['Yes', 'No']}
        />
    );
}

function InputField({ fieldType }) {
    const { fieldsGroupData } = useFieldsGroupContext();
    const fieldData = fieldsGroupData?.rightPart;
    const entityData = fieldData?.entityField;

    //if there is formula data shouldn't show the value
    const defaultValue = !fieldData.sign && (fieldData?.value || '');
    const getDefaultPutData = useGetDefaultPutData();

    const { refetchRule, allowRuleEdit } = useRuleContext();

    async function saveHandler(value) {
        const defaultData = getDefaultPutData();
        const putData = { ...defaultData };

        try {
            if (entityData?.id) {
                await deleteField({
                    entityFieldId: entityData.id,
                    left: false,
                    conditionField: fieldsGroupData.id,
                });
            }

            putData.value = value;

            // clears formula data in case there is any
            putData.sign = null;
            putData.type = fieldType;

            await updateField(putData);
            refetchRule();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div style={{ display: 'flex', position: 'relative' }}>
            <TextBox
                disabled={!allowRuleEdit}
                autoFocus={true}
                width="100%"
                placeholder={'Enter value'}
                stylingMode="underlined"
                defaultValue={defaultValue}
                onChange={(ev) => saveHandler(ev.event.target.value)}
            />
        </div>
    );
}

export default RightField;
