import { Column, DataGrid, FilterRow } from "devextreme-react/data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./monitoringQueue.scss";
import moment from "moment";
import axios from "../../services/axios/config";
import CustomStore from "devextreme/data/custom_store";
import {
  dateFilterOperations,
  flagStatuses,
  languageLabels,
  monitoringQueueFilterSelectors,
  monitoringQueueOrderSelectors,
  resolutionStatuses,
  routes,
  statusColorClasses,
} from "../../utils/vars";
import { useDispatch, useSelector } from "react-redux";
import useGetLabel from "../../hooks/useFindLabel";
import { useCheckPermission } from "../../hooks/useCheckPermission";
import useThrottle from "../../hooks/useThrottle";
import { Stack } from "@mui/system";
import { DateBox, TextBox } from "devextreme-react";
import {
  getDirection,
  getFilterParamsForMonitoringQueue,
} from "../../utils/functions";
import "devextreme/data/odata/store";
import AssignTo from "../flagDetails/AssignTo/AssignTo";
import Unlock from "../flagDetails/Unlock/Unlock";
import { setBackButtonRoute } from "../../redux/features/flagDetailsBackButtonRoute";
import {
  clearFilter,
  useGetDefaultFilters,
} from "../../redux/features/defaultTableFilters";

export default function MonitoringQueue() {
  const navigate = useNavigate();
  const lg = useSelector((state) => state.language);
  const resolutionTextPermission = useCheckPermission("RESOLUTION_TEXT");
  const [searchValue, handleChange] = useThrottle(500);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const assignToPermisson = useCheckPermission("ASSIGN_TO");
  const unlockRolePermission = useCheckPermission("UNLOCK");
  const [refetch, setRefetch] = useState(0);
  const dispatch = useDispatch();
  const { monitoringQueue: defaultFilters } = useGetDefaultFilters();

  const store = useMemo(
    () =>
      new CustomStore({
        key: "id",
        async load({ skip, take, sort, filter }) {
          const direction = getDirection(sort);
          const type = sort
            ? monitoringQueueOrderSelectors[sort[0].selector]
            : "DATE";
          const filterParams = filter
            ? getFilterParamsForMonitoringQueue(
                filter,
                monitoringQueueFilterSelectors
              )
            : "";

          let path = `flag/monitoring-queue/paginated?page=${
            skip / 15
          }&size=${take}&type=${type}&direction=${direction}&${filterParams}`;

          if (searchValue) {
            path += `&s=${searchValue}`;
          }

          try {
            const resp = await axios.get(path);
            const data = resp.data;

            return {
              data: data.data,
              totalCount: data.elements,
            };
          } catch (error) {
            throw new Error("Data Loading Error");
          }
        },
      }),
    []
  );

  useEffect(() => {
    return () => dispatch(clearFilter("monitoringQueue"));
  }, []);

  return (
    <div className="monitoring-queue-wrapper">
      <Stack
        flexDirection="row"
        justifyContent="end"
        gap="10px"
        sx={{ marginBottom: "10px" }}
      >
        <DateBox
          label="From date"
          stylingMode="underlined"
          width="150px"
          onValueChange={(v) =>
            setStartDateValue(v ? moment(v).format("yyyy-MM-DD") : v)
          }
        />
        <DateBox
          label="To date"
          stylingMode="underlined"
          width="150px"
          onValueChange={(v) =>
            setEndDateValue(v ? moment(v).format("yyyy-MM-DD") : v)
          }
        />
        <TextBox
          stylingMode="underlined"
          width={200}
          mode="search"
          onInput={(e) => handleChange(e.event)}
        />
      </Stack>

      <DataGrid
        remoteOperations={{
          paging: true,
          sorting: true,
          filtering: true,
        }}
        keyExpr={"id"}
        paging={{ enabled: true, pageSize: 15 }}
        onRowClick={(row) => {
          navigate(`/flag-details/${row.data.id}`);
          dispatch(setBackButtonRoute(routes.MONITORING_QUEUE));
        }}
        hoverStateEnabled={true}
        dataSource={store}
        filterOperations={["=", "between"]}
      >
        <FilterRow visible={true} />
        <Column
          allowSorting={false}
          caption="Flag ID"
          dataField={"id"}
          alignment={"left"}
          filterOperations={["="]}
        />
        <Column
          dataField={"date"}
          caption={useGetLabel("Date", lg)}
          dataType={"date"}
          filterOperations={dateFilterOperations}
          format={"yyyy-MM-dd"}
          editorOptions={{
            type: "date",
            displayFormat: "yyyy-MM-dd",
          }}
        />
        <Column
          dataField={"name"}
          dataType={"string"}
          caption={languageLabels.ruleName[lg]}
          filterOperations={["="]}
        />
        {/* <Column
          dataField={"data"}
          dataType={"string"}
          caption={languageLabels.author[lg]}
          filterOperations={["="]}
        /> */}
        <Column
          dataField={"tenant"}
          dataType={"string"}
          caption={useGetLabel("TenantNameLabel", lg)}
          filterOperations={["="]}
        />
        <Column
          dataField={"assignee"}
          dataType={"string"}
          caption={languageLabels.assignee[lg]}
          filterOperations={["="]}
          filterValue={defaultFilters?.assignee?.value || ""}
        />
        <Column
          dataField={"status"}
          dataType={"string"}
          // alignment={'center'}
          cellRender={(data) => (
            <div
              className={`${statusColorClasses[data.data?.status]} status-cell`}
            >
              {flagStatuses[data.data?.status]}
            </div>
          )}
          filterOperations={["=", "<>"]}
          filterValue={defaultFilters?.status?.value || ""}
        />
        {resolutionTextPermission && (
          <Column
            dataField={"resolution"}
            dataType={"string"}
            caption={languageLabels.resolution[lg]}
            cellRender={(data) => resolutionStatuses[data.data.resolution]}
            filterOperations={["="]}
          />
        )}

        {(assignToPermisson || unlockRolePermission) && (
          <Column
            // dataField={'resolution'}
            // caption={languageLabels.resolution[lg]}
            cellRender={(data) => {
              return !!data.data.assigneeId ? (
                <Unlock
                  refetch={() => setRefetch(refetch + 1)}
                  rowFlagId={data.data.id}
                />
              ) : (
                <AssignTo
                  refetch={() => setRefetch(refetch + 1)}
                  rowFlagId={data.data.id}
                />
              );
            }}
            filterOperations={["="]}
          />
        )}
      </DataGrid>
    </div>
  );
}
