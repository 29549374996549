import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import DetailsPopup from './detailsPopup/detailsPopup';
import { useSelector } from 'react-redux';
import { languageLabels } from '../../utils/vars';
import Stack from '@mui/material/Stack';
import { useRuleContext } from './ruleContext';
import * as ruleService from '../../services/rules';
import { errorMsg } from '../../utils/vars';
import SaveBtn from '../../components/saveBtn/saveBtn';
import { TextBox } from 'devextreme-react';
import { useToastContext } from '../../components/toast/toast';
import { getAxiosResponseError } from '../../utils/functions';
import DescriptionSection from './descriptionSection/descriptionSection';

export default function DashboardHeader({
    hasSubQueries,
    addSubQuery,
    isLoading,
    setIsLoading,
    isLoadingHistory,
    ruleHistory,
    setRefetchRuleHistory,
    type,
    setApplyFilter,
    description
}) {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const { ruleId, rule, allowRuleEdit } = useRuleContext();
    const lg = useSelector((state) => state.language);
    const navigate = useNavigate();

    return (
        <div className="rule-dashboard-header">
            <Stack
                gap={'10px'}
                flexDirection={'row'}
                justifyContent="space-between"
            >
                <Button
                    text={languageLabels.details[lg]}
                    type="default"
                    onClick={() => setDetailsOpen(true)}
                />
                <Stack gap={'10px'} flexDirection={'row'}>
                    {type === 'search' && (
                        <ApplyFilter setApplyFilter={setApplyFilter} />
                    )}
                    {!hasSubQueries && allowRuleEdit && (
                        <Button
                            text="Subquery"
                            onClick={addSubQuery}
                            stylingMode="outlined"
                            type="default"
                        />
                    )}

                    {type !== 'search' && (
                        <Button
                            type="default"
                            text={languageLabels.back[lg]}
                            onClick={() => navigate('/rules')}
                        />
                    )}
                </Stack>
            </Stack>
            {type !== 'search' && (
                <NameSection ruleId={ruleId} defaultName={rule?.name} />
            )}
            <DescriptionSection  description={description}/>
            {!rule.status === 'ACTIVATED' && (
                <p className="activeRuleLabel">
                    The rule is activate and cannot be modified. Please
                    deactivate it first in order to make changes.
                </p>
            )}

            <DetailsPopup
                isLoading={isLoading || isLoadingHistory}
                open={detailsOpen}
                onClose={() => setDetailsOpen(false)}
                status={rule.status}
                ruleHistory={ruleHistory}
                refetchRuleHistory={() =>
                    setRefetchRuleHistory((oldState) => oldState + 1)
                }
            />
        </div>
    );
}

function NameSection({ ruleId, defaultName }) {
    const [editNameMode, setEditNameMode] = useState(false);
    const { refetchRule } = useRuleContext();
    const { showErrorToast } = useToastContext();

    const changeToEditMode = () => setEditNameMode(true);
    const changeToReadOnlyMode = () => setEditNameMode(false);

    async function onNameSave(name) {
        try {
            await ruleService.editRuleName({
                id: ruleId,
                name,
            });
            refetchRule();
            changeToReadOnlyMode();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    return editNameMode ? (
        <div style={{ display: 'flex' }}>
            <TextBox
                // value={nameInputValue}
                defaultValue={defaultName}
                stylingMode="underlined"
                // onInput={(e) => setNameInputValue(e.event.currentTarget.value)}
                onChange={(ev) => onNameSave(ev.event.currentTarget.value)}
                onFocusOut={changeToReadOnlyMode}
            />
        </div>
    ) : (
        <h2 className="dx-theme-text-color" onClick={changeToEditMode}>
            {defaultName}
        </h2>
    );
}

function ApplyFilter({ setApplyFilter }) {
    function filterHandler() {
        setApplyFilter((oldState) => oldState + 1);
    }

    return (
        <Stack flexDirection={'row'} justifyContent={'end'}>
            <Button
                text="apply filter"
                type="default"
                onClick={filterHandler}
            />
        </Stack>
    );
}
