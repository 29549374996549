import {
  Button,
  DataGrid,
  DateBox,
  SelectBox,
  TextBox,
} from "devextreme-react";
import "./activityLog.scss";
import { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import axios from "../../services/axios/config";
import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../redux/features/filters";
import { useEffect } from "react";
import { formatDate } from "../../utils/functions";

export const ActivityLog = () => {
  const [filterValue, setFilterValue] = useState({});
  const [filterType, setFilterType] = useState(null);
  const [searchValue, setSearchValue] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(setFilters({}));
  }, []);

  function FilterSection() {
    const filterItems = [
      { value: "", label: "" },
      { value: "activity", label: "Activity" },
      { value: "date", label: "Date" },
      // { value: 'date', label: 'Date and hour' },
      { value: "user", label: "User" },
      { value: "role", label: "Role" },
      { value: "menu", label: "Menu" },
    ];

    const menuSelectItems = [
      { value: "RULE", label: "Rule" },
      { value: "MONITORING_QUEUE", label: "Monitoring qeue" },
      { value: "REPORTS", label: "Reports" },
    ];

    //TODO Add for translation

    const activitySelectItems = [
      { value: "CREATE_RULE", label: "Create rule" },
      { value: "UPDATE_RULE", label: "Update rule" },
      { value: "UPDATE_FLAG", label: "Update flag" },
      { value: "ESCALATE_FLAG", label: "Escalate flag" },
      {
        value: "GENERATED_SINGLE_REPORT",
        label: "Generate single report",
      },
      { value: "REPORT_EXPORT", label: "Report export" },
      { value: "GET_RULE", label: "Get rule" },
      { value: "DELETE_DATA_FIELD", label: "Delete data field" },
      {
        value: "UPDATE_DISPLAYED_ENTITY",
        label: "Update displayed entity",
      },
      { value: "UPDATE_RULE_STATUS", label: "Update rule status" },
      { value: "UPDATE_RULE_NAME", label: "Update rule name" },
      { value: "GET_FLAGS", label: "Get flags" },
      { value: "GET_FLAG_DETAILS", label: "Get flag details" },
      { value: "UPDATE_FLAG_STATUS", label: "Update flag status" },
      { value: "SINGLE_REPORT", label: "Single report" },
      { value: "MULTI_REPORT", label: "Multy report" },
      { value: "MONITORING_REPORT", label: "Monitoring report" },
      { value: "DAILY_REPORT", label: "Daily report" },
    ];

    const roleSelectItems = [
      { value: "ADMIN", label: "Admin" },
      { value: "AML_CORDINATOR", label: "AML coordinator" },
      { value: "AML_MANAGER", label: "AML manager" },
      { value: "AUDITOR", label: "Auditor" },
    ];

    let filterEl = null;

    switch (filterType) {
      case "activity":
        filterEl = (
          <SelectBox
            stylingMode="underlined"
            value={filterValue?.type === "activity" ? filterValue?.value : ""}
            displayExpr={"label"}
            valueExpr={"value"}
            onValueChange={(value) => {
              setFilterValue({ type: "activity", value });
            }}
            items={activitySelectItems}
          />
        );
        break;
      case "date":
        filterEl = (
          <DateBox
            stylingMode="underlined"
            value={filterValue?.type === "date" ? filterValue?.value : null}
            max={new Date()}
            // type="datetime"
            onValueChange={(value) => {
              setFilterValue({
                type: "date",
                value,
              });
            }}
          />
        );
        break;
      case "user":
        filterEl = (
          <TextBox
            stylingMode="underlined"
            onInput={(ev) =>
              setFilterType({
                type: "userName",
                value: ev.event.target.value,
              })
            }
          />
        );
        break;
      case "role":
        filterEl = (
          <SelectBox
            stylingMode="underlined"
            value={filterValue?.type === "role" ? filterValue?.value : ""}
            items={roleSelectItems}
            displayExpr={"label"}
            valueExpr={"value"}
            onValueChange={(value) => setFilterValue({ type: "role", value })}
          />
        );
        break;
      case "menu":
        filterEl = (
          <SelectBox
            stylingMode="underlined"
            items={menuSelectItems}
            value={filterValue?.type === "menu" ? filterValue?.value : ""}
            displayExpr={"label"}
            valueExpr={"value"}
            onValueChange={(value) => setFilterValue({ type: "menu", value })}
          />
        );
        break;
      default:
        break;
    }

    return (
      <div className="filter-section">
        <SelectBox
          width={260}
          stylingMode="underlined"
          value={filterType ? filterType : ""}
          label="Filter by"
          items={filterItems}
          displayExpr={"label"}
          valueExpr={"value"}
          onValueChange={(v) => {
            if (v === "") {
              searchFilterHandler(true);
            }
            setFilterValue({});
            setFilterType(v);
          }}
        />
        {filterEl}
        {filterType && <Button text="Filter" onClick={searchFilterHandler} />}
      </div>
    );
  }

  function searchFilterHandler(clearFilter) {
    let dispatchFilterValue = {};

    if (filterValue.type) {
      dispatchFilterValue[filterValue.type] = filterValue.value;
    }

    if (clearFilter === true) {
      dispatchFilterValue = {};
    }

    if (searchValue.type) {
      dispatchFilterValue[searchValue.type] = searchValue.value;
    }

    if (dispatchFilterValue.date) {
      dispatchFilterValue.date = formatDate(dispatchFilterValue.date)
    }

    dispatch(setFilters(dispatchFilterValue));
  }

  return (
    <div className="activity-log-wrapper">
      <div className="filter-search-section">
        <FilterSection />
        <div className="search-section">
          <TextBox
            width={260}
            stylingMode="underlined"
            mode="search"
            onInput={(e) =>
              setSearchValue({
                type: "search",
                value: e.event.target.value,
              })
            }
          />
          <Button text="Search" onClick={searchFilterHandler} />
        </div>
      </div>
      <Table />
    </div>
  );
};

const Table = React.memo(function Table() {
  const filter = useSelector((state) => state.filters);
  const postData = {
    date: filter.date || null,
    menu: filter.menu || null,
    activity: filter.activity || null,
    userName: filter.userName || null,
    role: filter.role || null,
    search: filter.search || null,
  };
  const customStore = new CustomStore({
    key: "",
    load(loadOptions) {
      const skip = loadOptions.skip;
      const take = loadOptions.take;
      const page = skip / take;

      return axios
        .post(`activity-log?page=${page}&size=${take}`, postData)
        .then((res) => {
          const data = res.data;
          return {
            data: data.activityLog,
            totalCount: data.totalCount,
          };
        })
        .catch((err) => {
          console.error(err);
          throw "Seomething happend. Try again later.";
        });
    },
  });

  return (
    <div className="table-wrapper">
      <DataGrid dataSource={customStore} remoteOperations={{ paging: true }}>
        <Column
          allowSorting={false}
          caption={"Activity"}
          dataField={"description"}
        />
        <Column
          allowSorting={false}
          caption={"Date"}
          dataField={"dateTime"}
          dataType={"datetime"}
        />
        <Column allowSorting={false} caption={"User"} dataField={"userName"} />
        <Column allowSorting={false} caption={"Role"} dataField={"userRole"} />
        <Column allowSorting={false} caption={"Menu"} dataField={"menu"} />
      </DataGrid>
    </div>
  );
});
