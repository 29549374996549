import { Button, DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import React, { useState } from "react";
import { Switch } from "devextreme-react/switch";
import { memo } from "react";
import { useActionsRoleContext } from "../../../contexts/actionsRoleProvider";
import { changeAction } from "../../../services/actions";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useToastContext } from "../../../components/toast/toast";
import { getAxiosResponseError } from "../../../utils/functions";

export default function ActionsPanel() {
  const { refetchActionsData } = useActionsRoleContext();
  const [changedActions, setChangedActions] = useState([]);
  const memoizedActionsDataHandler = useCallback(actionChangeHandler, []);
  const theme = useSelector((state) => state.theme);
  const { showErrorToast, showSuccessToast } = useToastContext();

  function actionChangeHandler(action, role, selected) {
    setChangedActions((oldState) => {
      const arrCopy = [...oldState];
      let index = -1;
      arrCopy.forEach((e, i) => {
        if (e.action === action && e.role === role) {
          index = i;
        }
      });
      if (!arrCopy.length || index === -1) {
        arrCopy.push({ action, role, selected });
      } else {
        arrCopy[index] = { action, role, selected };
      }
      return arrCopy;
    });
  }

  async function saveBtnHandler() {
    const promises = changedActions.map((e) => {
      return changeAction(e);
    });

    try {
      await Promise.all(promises);
      await refetchActionsData();
      setChangedActions([]);

      showSuccessToast("Changes saved.");
    } catch (err) {
      console.error(err);
      showErrorToast(getAxiosResponseError(err));
    }
  }

  return (
    <div className="actions-settings-wrapper">
      <div className="section-header">
        <p className="setting-label align-center">Roles actions</p>
        <div className="save-btn">
          <Button
            icon="save"
            stylingMode="outlined"
            onClick={saveBtnHandler}
            disabled={!changedActions?.length}
          />
        </div>
      </div>
      <div className={theme === "dark" ? "paper-dark-theme" : "paper"}>
        <Table actionChangeHandler={memoizedActionsDataHandler} />
      </div>
    </div>
  );
}

const Table = memo(function Table({ actionChangeHandler }) {
  const actionsLabels = {
    CREATE_RULE: "Create TRM rule",
    ACTIVATE_RULE: "Activate TRM rule",
    DEACTIVATE_RULE: "Deactivate TRM rule",
    EDIT_RULE: "Edit rule",
    PER_WHITELISTING: "Per whitelisting",
    WHITELISTING_PER_CLIENT: "Whitelisting per client",
    REVIEW_REPORTS: "Review reports",
    REVIEW_AUDIT: "Review audit trials",
    ESCALATE: "Escalate (button)",
    AML_INCREASE_RISK: "Send to AML increase risk (button)",
    MODIFY_SETTINGS: "Modify settings",
    LOGS: "Logs",
    RESOLVE: "Resolve",
    RESOLUTION_TEXT: "Resolution text",
    DOWNLOAD_REPORT: "Download report (if applicable)",
    MANUAL_SEARCH: "Manual search (one tenant)",
    MANUAL_SEARCH_MULTI: "Manual search (multiple tenants)",
    REVIEW_MONITORING: "Review monitoring qeueu (one tenant)",
    SEARCH_REVIEW_MONITORING:
      "Search and review monitoring queue (multiple tenants)",
  };
  const { actionsData } = useActionsRoleContext();

  function Cell({ data, roleI }) {
    const role = data?.roles[roleI].role;
    const action = data.action;
    return (
      <Switch
        defaultValue={data?.roles[roleI]?.selected}
        onValueChange={(v) => {
          actionChangeHandler(action, role, v);
        }}
      />
    );
  }
  return (
    <DataGrid dataSource={actionsData}>
      <Column
        allowResizing={true}
        caption={"Action"}
        cellRender={(data) => actionsLabels[data.data.action] || ""}
      />
      <Column
        allowResizing={true}
        caption={"Admin (IT admin)"}
        cellComponent={(data) => <Cell data={data?.data?.data} roleI={0} />}
        alignment={"center"}
      />
      <Column
        allowResizing={true}
        caption={"Backoffice specialist 1st level (AML coordinator)"}
        cellComponent={(data) => <Cell data={data?.data?.data} roleI={1} />}
        alignment={"center"}
      />
      <Column
        allowResizing={true}
        caption={"Backoffice specialist 2nd level (AML manager)"}
        cellComponent={(data) => <Cell data={data?.data?.data} roleI={2} />}
        alignment={"center"}
      />
      <Column
        alignment={"center"}
        allowResizing={true}
        cellComponent={(data) => <Cell data={data?.data?.data} roleI={3} />}
        caption={"Auditor"}
      />

      <Column
        alignment={"center"}
        allowResizing={true}
        cellComponent={(data) => <Cell data={data?.data?.data} roleI={4} />}
        caption={"Customer Support"}
      />
    </DataGrid>
  );
});
