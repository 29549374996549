import axios from "axios";

export const getAllTenants = async () => {
  const data = await axios.get(process.env.REACT_APP_BASE_URL + "/tenant/all");
  return data.data;
};

export const getLocalizedLabels = async () => {
  const data = await fetch("TranslationCroatia.json");
  return await data.json();
};
