import { useState } from "react";
import { useSelector } from "react-redux";
import { useToastContext } from "../../../components/toast/toast";
import axios from "../../../services/axios/config";
import { getAxiosResponseError } from "../../../utils/functions";
import { Button, Tooltip } from "devextreme-react";
import { languageLabels } from "../../../utils/vars";

export default function ExportButtons({
  hasFilters,
  reportType,
  setLoading,
  disabled,
  filters,
  singlePostData,
  filterParam,
}) {
  const [excelTooltipVisible, setExcelTooltipVisible] = useState(false);
  const [pdfTooltipVisible, setPdfTooltipVisible] = useState(false);
  const lg = useSelector((state) => state.language);
  const { showErrorToast } = useToastContext();

  //Export buttons are position absolute because there is a bug on hover otherwise

  function downloadFileDirectly(url, fileName) {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName; // Triggers a download with the given filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after download
  }

  function exportHandler(type) {
    setLoading(true);
    let postData = {};

    switch (reportType) {
      case "SINGLE":
        postData = singlePostData;
        postData.reportExportType = type;
        break;
      case "MULTI":
        postData = {
          type: "MULTI",
          clientStatus: filters?.clientStatus || "",
          changedLevelRisk:
            filters?.changedLevelRisk !== undefined
              ? filters?.changedLevelRisk
              : "",
          typeOfRule: filters?.typeOfRule || "",
          ruleName: filters?.ruleName || "",
          caseStatus: filters?.caseStatus || "",
          resolutionStatus: filters?.resolutionStatus || "",
          forExport: true,
          reportExportType: type,
        };
        break;
      case "DAILY":
        postData = {
          type: "DAILY",
          forExport: true,
          reportExportType: type,
        };
        break;
      case "FOR_MONITORING":
        postData = {
          type: "FOR_MONITORING",
          forExport: true,
          reportExportType: type,
        };
        break;
      default:
        break;
    }

    let url =
      reportType === "SINGLE" ? "report/single/export/checked" : "/report";

    if (filterParam) {
      const filtersObj = JSON.parse(filterParam);
      postData = { ...postData, ...filtersObj };
      // url = url + '?' + filterParam;
    }

    axios({
      url,
      method: "POST",
      responseType: "blob",
      data: postData,
    })
      .then((response) => {
        let fileName = type === "PDF" ? "file.pdf" : "file.xlsx";
        const contentDisposition = response.headers["content-disposition"];
        console.log("contentDisposition", contentDisposition);

        if (!!contentDisposition) {
          fileName = contentDisposition.split("filename=")[1];
        }

        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
        showErrorToast(getAxiosResponseError(err));
        setLoading(false);
      });
  }

  return (
    <div  className="flex-container gap-10">
      <div
        // style={excelBtnStyle}
        className={
          hasFilters ? "no-filter-excel download-excel" : "download-excel"
        }
        onMouseEnter={() => {
          if (!disabled) {
            setExcelTooltipVisible(true);
          }
        }}
        onMouseLeave={() => setExcelTooltipVisible(false)}
      >
        <Button
          disabled={disabled}
          id="export-excel"
          icon="exportxlsx"
          stylingMode="outlined"
          onClick={() => exportHandler("EXCEL")}
        />
      </div>
      <Tooltip
        target="#export-excel"
        visible={excelTooltipVisible}
        hideOnOutsideClick={false}
      >
        <div>{languageLabels.exportExcel[lg]}</div>
      </Tooltip>
      <div
        // style={pdfBtnStyle}
        className={hasFilters ? "no-filter-pdf download-pdf" : "download-pdf"}
        onMouseEnter={() => {
          if (!disabled) {
            setPdfTooltipVisible(true);
          }
        }}
        onMouseLeave={() => setPdfTooltipVisible(false)}
      >
        <Button
          disabled={disabled}
          onClick={() => exportHandler("PDF")}
          icon="exportpdf"
          stylingMode="outlined"
          id="export-pdf"
        />
      </div>
      <Tooltip
        target="#export-pdf"
        visible={pdfTooltipVisible}
        hideOnOutsideClick={false}
      >
        <div>{languageLabels.exportPdf[lg]}</div>
      </Tooltip>
    </div>
  );
}
